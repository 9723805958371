import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import CountUp from 'react-countup';
import ArtWidgetsListTable from './Widegets';

const AddPallets = () => {


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>

                   
                    <ArtWidgetsListTable/>
                    

                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddPallets;