import React, { useState, useEffect } from 'react';
import { Alert, Button, Card, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';

import ListViewTable from './ListViewTable';

const RolesListView = () => {
    const dispatch = useDispatch<any>();

    const [loader, setLoader] = useState<boolean>(false);
    const [rolelist, setSellerList] = useState<any>([]);
    const [modalAddSeller, setModalAddSeller] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [noData, setNoData] = useState<boolean>(false); 
    const [page, setPage] = useState(0); 
    const [limit, setLimit] = useState(10);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
            allies: '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required('Title is required'),
            allies: Yup.string().required('Allies is required'),
        }),
        onSubmit: async (values) => {
            setLoader(true);
            try {
                const tokenString = localStorage.getItem('authUser');
                let token = '';

                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
                    token = tokenObject.token;
                }

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/add_Roles`,
                    values,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                setModalAddSeller(false);
                validation.resetForm();
            
                fetchRolesList(page, limit);
                setShowSuccessAlert(true);
                setTimeout(() => setShowSuccessAlert(false), 300000);
            } catch (error) {
                console.error(error);
            } finally {
                setLoader(false);
            }
        },
    });

    const getToken = () => {
        const tokenString = localStorage.getItem('authUser');
        if (tokenString) {
            const tokenObject = JSON.parse(tokenString);
            return tokenObject.token || '';
        }
        return '';
    };

    const formatPublishedDate = (dateString: string | number | Date) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
    
        const getDayWithSuffix = (day: number) => {
            if (day > 3 && day < 21) return day + 'th';
            switch (day % 10) {
                case 1: return day + 'st';
                case 2: return day + 'nd';
                case 3: return day + 'rd';
                default: return day + 'th';
            }
        };
    
        return `${getDayWithSuffix(day)} ${month}, ${year}`;
    };

    const fetchRolesList = async (page: number, limit: number) => {
        setLoader(true);
        try {
            const token = getToken();
            const queryParams = new URLSearchParams({
                search: debouncedSearchTerm,
                page: page.toString(),
                limit: limit.toString()
            }).toString();

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_all_roles?${queryParams}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
console.log(response?.data?.length, "length")
            if (response?.data?.length === 0) {
                setSellerList([]); 
                setNoData(true);
            } else {
                setSellerList(response.data);
                setNoData(false);
            }
        
         
        } catch (error) {
            console.error("Error fetching roles list:", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchRolesList(page, limit);
    }, [debouncedSearchTerm, page, limit]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        setSearchTerm(value);
    

        if (value === "") {
            setNoData(false); 
            fetchRolesList(page, limit);
        } else {
            setNoData(true);
            setSellerList([]); 
        }
    };
    console.log(rolelist, "rolelistrolelistrolelist");

    let listrole = [];
    if (rolelist.length > 0) {
      listrole = rolelist.map((item: { id: any; Alias: any; Title: any; created_at: any; }) => ({
        id: item.id,
        Alias: item.Alias,
        Title: item.Title,
        created_at: formatPublishedDate(item.created_at),
      }));
    }
console.log(noData, "noDatanoData")
    console.log(listrole.length, "listrolelistrole")
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="User Role " pageTitle="User role" />
                    <Row>
                        <Col xxl={3} md={6}>
                            <Card className="bg-light border-0">

                            </Card>
                        </Col>
                    </Row>

                    {showSuccessAlert && (
                        <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                            Roles are  added successfully!
                        </Alert>
                    )}

                    <Row id="sellersList">
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Row className="g-3">
                                        <Col lg={3}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                            />
                                        </Col>
                                        <Col className="col-lg-auto ms-auto">
                                            <div className="hstack gap-2">
                                                <Button
                                                    variant='primary'
                                                    className="add-btn"
                                                    onClick={() => setModalAddSeller(true)}
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i> Add Roles
                                                </Button>
                                                <Dropdown>
                                                    <Dropdown.Toggle className="btn-icon btn btn-soft-dark arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="ph-dots-three-outline"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as="ul">
                                                        <li><Link className="dropdown-item" to="#">Action</Link></li>
                                                        <li><Link className="dropdown-item" to="#">Another action</Link></li>
                                                        <li><Link className="dropdown-item" to="#">Something else here</Link></li>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Modal className="fade addAdmin" show={modalAddSeller} onHide={() => { setModalAddSeller(false); }} centered>
                                <Modal.Header className="px-4 pt-4" closeButton>
                                    <h5 className="modal-title" id="exampleModalLabel">Add Roles</h5>
                                </Modal.Header>
                                <Form className="tablelist-form" onSubmit={validation.handleSubmit}>
                                    <Modal.Body className="p-4">
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="title">Title<sup>*</sup></Form.Label>
                                                    <Form.Control type="text" name="title" id="title"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.title || ""}
                                                        isInvalid={
                                                            validation.touched.title && validation.errors.title ? true : false
                                                        }
                                                    />
                                                    {validation.touched.title && validation.errors.title ? (
                                                        <Form.Control.Feedback type="invalid"><div>{validation.errors.title}</div></Form.Control.Feedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="allies">Allies<sup>*</sup></Form.Label>
                                                    <Form.Control type="text" name="allies" id="allies"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.allies || ""}
                                                        isInvalid={
                                                            validation.touched.allies && validation.errors.allies ? true : false
                                                        }
                                                    />
                                                    {validation.touched.allies && validation.errors.allies ? (
                                                        <Form.Control.Feedback type="invalid"><div>{validation.errors.allies}</div></Form.Control.Feedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <div className="modal-footer">
                                        <div className="hstack gap-2 justify-content-end">
                                            <Button variant='success' type='submit' id="add-btn">Add Roles</Button>
                                        </div>
                                    </div>
                                </Form>
                            </Modal>
                            <Card>
                                <Card.Body className='p-0'>
                                    {/* <ListViewTable allrolelist={listrole} fetchRolesList={fetchRolesList} /> */}
                                    {noData ? (
        // If no data is available, show the "No Results Found" message
        <div className="text-center py-4">
            <div className="avatar-md mx-auto mb-4">
                <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                    <i className="bi bi-search"></i>
                </div>
            </div>
            <h5 className="mt-2">Sorry! No Result Found</h5>
        </div>
    ) : (
        // If data is available, render the ListViewTable
        <ListViewTable allrolelist={listrole} fetchRolesList={fetchRolesList} />
    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default RolesListView;
