import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface SuccessModalProps {
    show: boolean;
    onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ show, onClose }) => (
    <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>Artwork</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Artwork created successfully!</p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
            <div>
                <a href="/artworks/artworks-v1" className="btn btn-primary" id="addproduct-btn">
                    <i className="ri-add-line align-bottom me-1"></i>Add Artwork
                </a>
            </div>
            <Button variant="btn btn-success" onClick={onClose}>
                Back to home
            </Button>
        </Modal.Footer>
    </Modal>
);

export default SuccessModal;
