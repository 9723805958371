import axios from 'axios';
import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

interface ArtistEssayProps {
    formik: any;
}

const ArtistEssay: React.FC<ArtistEssayProps> = ({ formik }) => {
    const [imageData, setImageData] = React.useState(null);

    const { values, errors, touched, handleChange, setFieldValue } = formik;

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files[0]) {
            try {
                let data: any = new FormData();
                data.append('fileType', 'artist_thumb_desktop');
                data.append('filePath', event.target.files[0]);

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data.mediaPaths[0].filePath));
                        setFieldValue(fieldName, response.data.mediaPaths[0].filePath);
                        console.log('imageData', response.data.mediaPaths[0].filePath)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Error on upload image ", error);
            }
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    <h5 className="fs-16">Artist Essays</h5>
                    <p className="text-muted mb-lg-0">Personal Identifiable Information (PII) is defined as: Any representation of information that permits the identity of an individual to whom the information applies to be reasonably inferred by either direct or indirect means.</p>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Row className="g-3">
                                <Col lg={12}>
                                    <Form.Group controlId="photo_essay_title">
                                        <Form.Label>Photo Essay Title  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="photo_essay_title"
                                            placeholder="Enter Photo Essay Title"
                                            value={values.photo_essay_title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.photo_essay_title && touched.photo_essay_title}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.photo_essay_title}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="photo_essay_description">
                                        <Form.Label>Photo Essay Description  </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="photo_essay_description"
                                            placeholder="Enter Photo Essay Description"
                                            value={values.photo_essay_description}
                                            onChange={handleChange}
                                            isInvalid={!!errors.photo_essay_description && touched.photo_essay_description}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.photo_essay_description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_title1">
                                        <Form.Label>Essay Title 1  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="essay_title1"
                                            placeholder="Enter Essay Title 1"
                                            value={values.essay_title1}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_title1 && touched.essay_title1}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_title1}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_image1">
                                        <Form.Label>Essay Image One</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="essay_image1"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "essay_image1")}
                                            isInvalid={!!errors.essay_image1 && touched.essay_image1}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_image1}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_description1">
                                        <Form.Label>Essay One Description  </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="essay_description1"
                                            placeholder="Enter Essay One Description"
                                            value={values.essay_description1}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_description1 && touched.essay_description1}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_description1}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_title2">
                                        <Form.Label>Essay Title 2  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="essay_title2"
                                            placeholder="Enter Essay Title 2"
                                            value={values.essay_title2}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_title2 && touched.essay_title2}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_title2}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_image2">
                                        <Form.Label>Essay Image Two</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="essay_image2"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "essay_image2")}
                                            isInvalid={!!errors.essay_image2 && touched.essay_image2}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_image2}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_description2">
                                        <Form.Label>Essay Two Description  </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="essay_description2"
                                            placeholder="Enter Essay Two Description"
                                            value={values.essay_description2}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_description2 && touched.essay_description2}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_description2}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>


                                <Col lg={12}>
                                    <Form.Group controlId="essay_title3">
                                        <Form.Label>Essay Title 3  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="essay_title3"
                                            placeholder="Enter Essay Title 3"
                                            value={values.essay_title3}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_title3 && touched.essay_title3}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_title3}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_image3">
                                        <Form.Label>Essay Image Three</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="essay_image3"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "essay_image3")}
                                            isInvalid={!!errors.essay_image3 && touched.essay_image3}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_image3}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_description3">
                                        <Form.Label>Essay Three Description  </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="essay_description3"
                                            placeholder="Enter Essay Three Description"
                                            value={values.essay_description3}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_description3 && touched.essay_description3}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_description3}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_title4">
                                        <Form.Label>Essay Title 4  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="essay_title4"
                                            placeholder="Enter Essay Title 4"
                                            value={values.essay_title4}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_title4 && touched.essay_title4}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_title4}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_image4">
                                        <Form.Label>Essay Image Four</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="essay_image4"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "essay_image4")}
                                            isInvalid={!!errors.essay_image4 && touched.essay_image4}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_image4}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_description4">
                                        <Form.Label>Essay Four Description  </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="essay_description4"
                                            placeholder="Enter Essay Four Description"
                                            value={values.essay_description4}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_description4 && touched.essay_description4}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_description4}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>



                                <Col lg={12}>
                                    <Form.Group controlId="essay_title5">
                                        <Form.Label>Essay Title 5  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="essay_title5"
                                            placeholder="Enter Essay Title 5"
                                            value={values.essay_title5}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_title5 && touched.essay_title5}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_title5}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_image5">
                                        <Form.Label>Essay Image Five</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="essay_image5"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "essay_image5")}
                                            isInvalid={!!errors.essay_image5 && touched.essay_image5}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_image5}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_description5">
                                        <Form.Label>Essay Five Description  </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="essay_description5"
                                            placeholder="Enter Essay Five Description"
                                            value={values.essay_description5}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_description5 && touched.essay_description5}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_description5}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_title6">
                                        <Form.Label>Essay Title 6  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="essay_title6"
                                            placeholder="Enter Essay Title 6"
                                            value={values.essay_title6}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_title6 && touched.essay_title6}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_title6}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_image6">
                                        <Form.Label>Essay Image Six</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="essay_image6"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "essay_image6")}
                                            isInvalid={!!errors.essay_image6 && touched.essay_image6}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_image6}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group controlId="essay_description6">
                                        <Form.Label>Essay Six Description  </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="essay_description6"
                                            placeholder="Enter Essay Six Description"
                                            value={values.essay_description6}
                                            onChange={handleChange}
                                            isInvalid={!!errors.essay_description6 && touched.essay_description6}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.essay_description6}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default ArtistEssay;