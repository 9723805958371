import BreadCrumb from "Common/BreadCrumb";
import StepperWizard from "Common/Stepper";
import React from "react";
import {  Container } from "react-bootstrap";

function AddArtwoks() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Create Artwork" pageTitle="Artwork" />
          <StepperWizard />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AddArtwoks;
