import React from 'react';
//import Scss
import "./assets/scss/themes.scss";
//imoprt Route
import Route from './Routes/Index';

const App = () => {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
