import axios from 'axios';
import React from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';

interface CreateProductStep1Props {
    formik: any;
}

const SeoStep3: React.FC<CreateProductStep1Props> = ({ formik }) => {
    document.title = "Categories | Artflute + Admin React Template"

    const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = formik;

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files[0]) {
            try {
                let data: any = new FormData();
                data.append('fileType', 'artist_thumb_desktop');
                data.append('filePath', event.target.files[0]);

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data.mediaPaths[0].filePath));
                        setFieldValue(fieldName, response.data.mediaPaths[0].filePath);
                        console.log('imageData', response.data.mediaPaths[0].filePath)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Error on upload image ", error);
            }
        }
    };

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Seo</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form
                                        autoComplete="off"
                                        className="needs-validation createCategory-form"
                                        id="createCategory-form"
                                        noValidate
                                        onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_title">
                                                    <Form.Label>Seo Title </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="seo_title"
                                                        placeholder="Enter Seo Title"
                                                        value={values.seo_title}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.seo_title && touched.seo_title}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_title}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_keyword">
                                                    <Form.Label>Seo Keyword </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="seo_keyword"
                                                        placeholder="Enter Seo Keyword"
                                                        value={values.seo_keyword}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.seo_keyword && touched.seo_keyword}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_keyword}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_description">
                                                    <Form.Label>Seo Description </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="seo_description"
                                                        placeholder="Seo description"
                                                        value={values.seo_description}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.seo_description && touched.seo_description}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_description}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="seo_image">
                                                    <Form.Label>Seo image</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="seo_image"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "seo_image")}
                                                        isInvalid={!!errors.seo_image && touched.seo_image}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.seo_image}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default SeoStep3;
