



import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Button, Card, Col, Container, Form, Modal, Offcanvas, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Breadcrumb from 'Common/BreadCrumb';
import { categoryListData} from 'Common/data';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { isTemplateExpression } from 'typescript';

const Categories = () => {
    document.title = "Categories | Artflute + Admin React Template";

    const [show, setShow] = useState<boolean>(false);
    const [info, setInfo] = useState<any>([]);
    const [pagination, setPagination] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentpages, setCurrentpages] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [categorylist, setcategoryList] = useState<any[]>([]);
    const [selectedcategory, setSelectedcategory] = useState<any>(null);
    const [deleteSellerId, setDeleteSellerId] = useState<any>(null); 
    const [statusdate, setstatusdate] =useState<any>([]);;
    const perPageData = 9;
    const handleClick = (e: any) => {
        setCurrentPage(Number(e.target.id));
    };

    const fetchcategoryList = async () => {
        setLoader(true);
        try {
            const tokenString = localStorage.getItem('authUser');
            let token = '';

            if (tokenString) {
                const tokenObject = JSON.parse(tokenString);
                token = tokenObject.token;
            }

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/getall_category`, 
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            setcategoryList(response.data);

        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchcategoryList();
    }, []);

    const categoryListData = useMemo(() => {
        return categorylist.map((item: any) => ({
            id:item.id,
            Category_id: item.Category_id,
            Art_style: item.Art_style,
            Art_subject: item.Art_subject,
            Art_type: item.Art_type,
            Colors: item.Colors,
            Edition_size: item.Edition_size,
            Mediums: item.Mediums,
            Palette: item.Palette,
            Shipped_as: item.Shipped_as,
            Signature: item.Signature,
            Surface: item.Surface,
            Tags: item.Tags,
            Timelines: item.Timelines,

            Year_released: item.Surface

            
        }));
    }, [categorylist]);

    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;

    const currentdata = useMemo(() => {
        return categoryListData.slice(indexOfFirst, indexOfLast);
    }, [indexOfFirst, indexOfLast, categoryListData]);

    useEffect(() => {
        setCurrentpages(currentdata);
    }, [currentdata]);

    const searchTeamMember = (ele: any) => {
        let search = ele.target.value;
        if (search) {
            search = search.toLowerCase();
            setCurrentpages(
                categoryListData.filter((data: any) =>
                    Object.values(data)
                        .some(value => typeof value === 'string' && value.toLowerCase().includes(search))
                )
            );
            
            setPagination(false);
        } else {
            setCurrentpages(currentdata);
            setPagination(true);
        }
    };
    const pageNumbers: number[] = [];
    for (let i = 1; i <= Math.ceil(categoryListData.length / perPageData); i++) {
        pageNumbers.push(i);
    }

    const handleprevPage = () => {
        let prevPage = currentPage - 1;
        setCurrentPage(prevPage);
    };

    const handlenextPage = () => {
        let nextPage = currentPage + 1;
        setCurrentPage(nextPage);
    };

    useEffect(() => {
        if (pageNumbers.length && currentPage > pageNumbers.length) {
            setCurrentPage(pageNumbers.length);
        }
    }, [currentPage, pageNumbers.length]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: '',
            art_type: '',
            palette: '',
            colors: '',
            art_subject: '',
            surface: '',
            category_id: '',
            shipped_as: '',
            year_released: '',
            timelines: '',
            edition_size: '',
            signature: '',
            tags: '',
            mediums: '',
            art_style: ''
        },
        validationSchema: Yup.object({}),
        onSubmit: async () => {
            handleAddCategory();
        }
    });

    const handleAddCategory = async () => {
        setLoader(true);
        try {
            const tokenString = localStorage.getItem('authUser');
            let token = '';

            if (tokenString) {
                const tokenObject = JSON.parse(tokenString);
                token = tokenObject.token;
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASEURL}/auth/Add_categories`,
                validation.values,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            validation.resetForm();
            setShowSuccessAlert(true);
            fetchcategoryList()
            setTimeout(() => setShowSuccessAlert(false), 3000);
        } catch (error) {
            console.error("Error adding category:", error);
        } finally {
            setLoader(false);
        }
    };
console.log(currentpages, "currentpagescurrentpagescurrentpagescurrentpagescurrentpagescurrentpages")


console.log(JSON.stringify(currentpages, null, 2), "Response currentpagescurrentpagescurrentpagescurrentpagescurrentpagescurrentpages");


const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const handleShow = (item: any) => {
        setSelectedItem(item);
        setShowModal(true);
    };

   
 
    console.log( categorylist, "222222222222222")
   
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: '',
            art_type: '',
            palette: '',
            colors: '',
            art_subject: '',
            surface: '',
            category_id: '',
            shipped_as: '',
            year_released: '',
            timelines: '',
            edition_size: '',
            signature: '',
            tags: '',
            mediums: '',
            art_style: ''
        },
        validationSchema: Yup.object({
            // palette: Yup.string().required('Palette is required'),
            // Add more validation rules as needed
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log(values, "Form values");
            setLoader(true);

            try {
                const tokenString = localStorage.getItem('authUser');
                const token = tokenString ? JSON.parse(tokenString)?.token : '';

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/edit_category`,
                    values,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );

                resetForm();
                setShowModal(false); 
                fetchcategoryList()

            } catch (error) {
                console.error("Error submitting form:", error);
            } finally {
                setLoader(false);
            }
        },
    });

    console.log(categorylist, "Category List");
    const filteredSellers = categorylist.filter((seller: any) => seller.id === '1');
    const sellerToEdit = categorylist.find((seller: any) => seller.id == '1');
    console.log(sellerToEdit, "Filtered Sellers");

    const handleEditClick = (id: any) => {
        console.log(id, "ID passed to handleEditClick");
    
        if (categorylist.length === 0) {
            console.log("No categories available to edit.");
            return;
        }
    
        const filteredCategories = categorylist.filter((category: any) => category.id === id);
        console.log(filteredCategories, "Selectedssss Category for Editing");
        if (filteredCategories.length > 0) {
            const categoryToEdit = filteredCategories[0]; 
    
            console.log(categoryToEdit, "Selected Category for Editing");
    
            setSelectedcategory(categoryToEdit);
            formik.setValues({
                id: categoryToEdit.id || '',
                art_type: categoryToEdit.Art_Type || '',
                palette: categoryToEdit.Palette || '',
                colors: categoryToEdit.Colors || '',
                art_subject: categoryToEdit.Art_Subject || '',
                surface: categoryToEdit.Surface || '',
                category_id: categoryToEdit.Category_id || '',
                shipped_as: categoryToEdit.Shipped_as || '',
                year_released: categoryToEdit.Year_Released || '',
                timelines: categoryToEdit.Timelines || '',
                edition_size: categoryToEdit.Edition_size || '',
                signature: categoryToEdit.Signature || '',
                tags: categoryToEdit.Tags || '',
                mediums: categoryToEdit.Mediums || '',
                art_style: categoryToEdit.Art_style || '',
            });
            setShowModal(true);
        } else {
            console.log("Category not found.");
        }
    };
    
    
    
    const handleClose = () => setShowModal(false);
    const [modal_DeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
 
    const handleDeleteClick = (id: any) => {
        setSelectedItem(id);
        setModalDeleteConfirm(true);
    };

    

  
    const handleDeleteConfirm =  async (id: any) => {
        try {

            const tokenString = localStorage.getItem('authUser');
            let token = '';

            if (tokenString) {
                const tokenObject = JSON.parse(tokenString);
                token = tokenObject.token;
            }

            setLoader(true);
          const yy=   await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/delete_category`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    params: { id: id },
                }
            );
          
            setDeleteSellerId(id)
            console.log( id, "idididididididiididiididiidididiid")
            setModalDeleteConfirm(false); 
            fetchcategoryList()
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };
        console.log('Deleting item:', selectedItem);
   
    
console.log(deleteSellerId, "currentpagescurrentpagescurrentpagescurrentpagescurrentpages")



const fetchStatusanddateList = async () => {
    setLoader(true);
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASEURL}/auth/get_dates_status`, 
            {
               
            }
        );
        setstatusdate(response.data);
    } catch (error) {
        console.error("Error fetching seller list:", error);
    } finally {
        setLoader(false);
    }
};

useEffect(() => {
    fetchStatusanddateList();
}, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Categories" pageTitle="Products" />
                    {showSuccessAlert && (
                <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                    categories are  added successfully!
                </Alert>
            )}
                    <Row>
                        <Col xxl={3}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Create Categories</h6>
                                </Card.Header>
                                <Card.Body>
                                <Form 
                                      autoComplete="off"
                                      className="needs-validation createCategory-form"
                                      id="createCategory-form"
                                      noValidate
                                      onSubmit={validation.handleSubmit}
                                    >
                                        <input type="hidden" id="categoryid-input" className="form-control" value="" />
                                        <Row>
                                       
                                         


                                               








                                                <Col md={6}>
                                                <div className="mb-3">
    <Form.Label htmlFor="surface">Surface</Form.Label>
    <Form.Control 
        as="select" 
        id="surface" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.surface || ""}
        isInvalid={
            validation.touched.surface && validation.errors.surface ? true : false
        }
    >



<option value="100_acid_free_reg_paper">100% Acid free Reg paper</option>
	    		

                <option value="acrylic_sheet">Acrylic Sheet</option>
                  
  
                <option value="arches_paper">Arches paper</option>
                  
  
                <option value="board">Board</option>
                  
  
                <option value="canvas">Canvas</option>
                  
  
                <option value="handmade paper">Handmade Paper </option>

    </Form.Control>
</div>

                                                </Col>




                                                



{/* <Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="category_id">Category <span className="text-danger">*</span></Form.Label>
        <Form.Control 
            as="select" 
            id="category_id" 
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.category_id || ""}
            isInvalid={
                validation.touched.category_id && validation.errors.category_id ? true : false
            }

        >
            const yy= validation.values.category_id filter(1)
        
            <option value="">Select Category</option>
            <option value="">Painting</option>
            <option value="Sculpture">Sculpture</option>
            <option value="Limited Edition Prints">Limited Edition Prints</option>
            <option value="Drawing">Drawing</option>
            <option value="Photograph">Photograph</option>
            <option value="Digital Art">Digital Art</option>
            <option value="Art Collectibles">Art Collectibles</option>
            <option value="Mixed Media">Mixed Media</option>
            <option value="Installation">Installation</option>
            <option value="Serigraph">Serigraph</option>
            <option value="Print Making">Print Making</option>
        </Form.Control>
    </div>
</Col> */}


<Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="category_id">
            Category <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control 
            as="select" 
            id="category_id" 
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.category_id || ""}
            isInvalid={
                validation.touched.category_id && validation.errors.category_id ? true : false
            }
        >
            <option value="">Select Category</option>
            {[
                "Painting",
                "Sculpture",
                "Limited Edition Prints",
                "Drawing",
                "Photograph",
                "Digital Art",
                "Art Collectibles",
                "Mixed Media",
                "Installation",
                "Serigraph",
                "Print Making",
            ].map((categoryyy) => (
                <option 
                    key={categoryyy} 
                    value={categoryyy}
                >
                    {categoryyy}
                </option>
            ))}
        </Form.Control>
    </div>
</Col>
                                                <Col md={6}>
                                                <div className="mb-3">
    <Form.Label htmlFor="art_subject">Art Subject</Form.Label>
    <Form.Control 
        as="select" 
        id="art_subject" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.art_subject || ""}
        isInvalid={
            validation.touched.art_subject && validation.errors.art_subject ? true : false
        }
    >
        <option value="Abstract">Abstract</option>
                            <option value="Animals">Animals</option>
                            <option value="Architecture">Architecture</option>
                            <option value="Birds">Birds</option>
                            <option value="Botanical">Botanical</option>
                            <option value="Children">Children</option>
                            <option value="City/Cityscape">City/Cityscape</option>
                            <option value="Dance">Dance</option>
                            <option value="Dream">Dream</option>

    </Form.Control>
</div>

                                                </Col>


                                                <Col md={6}>
                                                <div className="mb-3">
    <Form.Label htmlFor="users_status">Art Style</Form.Label>
    <Form.Control 
        as="select" 
        id="art_style" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.art_style || ""}
        isInvalid={
            validation.touched.art_style && validation.errors.art_style ? true : false
        }
    >
      <option value="Abstract">Abstract</option>
                            <option value="Figurative">Figurative</option>
                            <option value="Folk">Folk</option>
                            <option value="Decorative">Decorative</option>
                            <option value="Cubism">Cubism</option>
                            <option value="Realism">Realism</option>
                            <option value="Impressionism">Impressionism</option>
                            <option value="Expressionism">Expressionism</option>

    </Form.Control>
</div>

                                                </Col>

                                                <Col md={6}>
                                                <div className="mb-3">
    <Form.Label htmlFor="users_status">Mediums</Form.Label>
    <Form.Control 
        as="select" 
        id="mediums" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.mediums || ""}
        isInvalid={
            validation.touched.mediums && validation.errors.mediums ? true : false
        }
    >
        <option value="Pen and Ink">Pen and Ink</option>
                            <option value="Acrylic">Acrylic</option>
                            <option value="Encaustic">Encaustic</option>
                            <option value="Gouache">Gouache</option>
                            <option value="Egg Tempera">Egg Tempera</option>
                            <option value="Mixed Media">Mixed Media</option>
                            <option value="Oil">Oil</option>
                            <option value="Watercolour and Gouache">Watercolour and Gouache</option>
                            <option value="Acrylic and Oil">Acrylic and Oil</option>
                            <option value="Watercolour and Acrylic">Watercolour and Acrylic</option>
                            <option value="Tempera">Tempera</option>
                            <option value="Watercolor">Watercolor</option>

    </Form.Control>
</div>

                                                </Col>
                                                <Col md={6}>
                                                <div className="mb-3">
    <Form.Label htmlFor="users_status">Art Type</Form.Label>
    <Form.Control 
        as="select" 
        id="art_type" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.art_type || ""}
        isInvalid={
            validation.touched.art_type && validation.errors.art_type ? true : false
        }
    >
        <option value="Roll form">Roll form</option>
                            <option value="Stretched canvas">Stretched canvas</option>
                            <option value="On hard board">On hard board</option>
                            <option value="Pre framed">Pre framed</option>
                            <option value="Test Art Types">Test Art Types</option>

    </Form.Control>
</div>
</Col>

 

<Col md={6}>
<div className="mb-3">
    <Form.Label htmlFor="palette">Palette</Form.Label>
    <Form.Control 
        as="select" 
        id="palette" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.palette || ""}
        isInvalid={
            validation.touched.palette && validation.errors.palette ? true : false
        }
    >
         <option value="Cement">Cement</option>
                            <option value="Purple">Purple</option>
                            <option value="Green">Green</option>
                            <option value="Pink">Pink</option>
                            <option value="Red">Red</option>
                            <option value="Orange">Orange</option>
                            <option value="Yellow">Yellow</option>
                            <option value="Blue">Blue</option>

    </Form.Control>
</div>
</Col>


<Col xxl={12} lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="slugInput">Tags</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="slugInput"
                                                        placeholder="Enter tags"
                                                        {...validation.getFieldProps('tags')}
                                                        isInvalid={validation.touched.tags && validation.errors.tags ? true : false}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validation.errors.tags}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Col>




                                            


                                            <Col xxl={12} lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="slugInput">Signature </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="slugInput"
                                                        placeholder="Enter signature"
                                                        {...validation.getFieldProps('signature')}
                                                        isInvalid={validation.touched.signature && validation.errors.signature ? true : false}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validation.errors.signature}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Col>

                                            <Col xxl={12} lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="slugInput">Edition Size</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="edition_sizeInput"
                                                        placeholder="Enter edition_size"
                                                        {...validation.getFieldProps('edition_size')}
                                                        isInvalid={validation.touched.edition_size && validation.errors.edition_size ? true : false}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validation.errors.edition_size}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Col>


                                            <Col xxl={12} lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="slugInput">Timelines</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="timelinesInput"
                                                        placeholder="Enter timelines"
                                                        {...validation.getFieldProps('timelines')}
                                                        isInvalid={validation.touched.timelines && validation.errors.timelines ? true : false}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validation.errors.timelines}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Col>



                                            <Col xxl={12} lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="slugInput">Year Released</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="year_releasedInput"
                                                        placeholder="Enter year_released"
                                                        {...validation.getFieldProps('year_released')}
                                                        isInvalid={validation.touched.year_released && validation.errors.year_released ? true : false}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validation.errors.year_released}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Col>


                                            <Col xxl={12} lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="slugInput">Colors</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="colorsInput"
                                                        placeholder="Enter colors"
                                                        {...validation.getFieldProps('colors')}
                                                        isInvalid={validation.touched.colors && validation.errors.colors ? true : false}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validation.errors.colors}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Col>


                                            <Col md={6}>
<div className="mb-3">
    <Form.Label htmlFor="palette">Shipped as <span className="text-danger">*</span></Form.Label>
    <Form.Control 
        as="select" 
        id="shipped_as" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.shipped_as || ""}
        isInvalid={
            validation.touched.shipped_as && validation.errors.shipped_as ? true : false
        }
    >
        <option value="Framed">Framed</option>
	    		

                <option value="Rolled">Rolled</option>
                  
  
                <option value="Stretched">Stretched</option>
                  
  
                <option value="Crate">Crate</option>
  
    </Form.Control>
</div>
</Col>
                                            <Col xxl={12}>
                                                <div className="text-end">
                                                    <Button variant='success' type="submit">Add Category</Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={9}>
                            <Row className="justify-content-between mb-4">
                                <Col xxl={3} lg={6}>
                                    <div className="search-box mb-3 mb-lg-0">
                                        <Form.Control type="text" id="searchInputList" placeholder="Search Category..." onChange={(e) => searchTeamMember(e)} />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </Col>
                                <Col xxl={2} lg={6}>
                                    <select className="form-select" data-choices data-choices-search-false name="choices-single-default" id="idStatus">
                                    {statusdate?.dates?.map((status:any, index:number) => (
                                            <option key={index} value={status}>{status}</option>
                                        ))}
          
                                    </select>
                                </Col>
                            </Row>

                            <Row id="categories-list">
                                {(currentpages || []).map((item: any, key: number) => (<Col xxl={4} md={6} key={key}>
                                    <Card className="categrory-widgets overflow-hidden">
                                        <Card.Body className="p-4">
                                            <div className="d-flex align-items-center mb-3">
                                                
                                            <h5 className="flex-grow-1 mb-0">
                        {item.Category_name ? item.Category_name : ''}
                    </h5>

                                                <ul className="flex-shrink-0 list-unstyled hstack gap-1 mb-0">
                                                    {/* <li><Link to="#" className="badge bg-info-subtle text-info">Edit</Link></li> */}
                                                    <li>
                                                    <Button
    variant="info"
    style={{ fontSize: '0.75rem', padding: '0.25rem 0.5rem' }}
    onClick={() => {
        console.log(item.id, "ID passed to handleEditClick");
        handleEditClick(item.id);
    }}
>
    Edit
</Button>

                                    </li>
                                    <Button
                            variant='soft-danger'
                            size="sm"
                            className="remove-item-btn"
                            onClick={() => handleDeleteClick(item.id)}
                        >
                            Remove
                        </Button>
                                    
                                                    {/* <li><Link to="#" data-bs-toggle="modal" className="badge bg-danger-subtle text-danger">Delete</Link></li> */}
                                                </ul>
                                            </div>
                                            <ul className="list-unstyled vstack gap-2 mb-0">
                    {Object.values(item).length > 0 ? (
                        Object.values(item).map((value, index) => (
                       
                            value !== item.Category_name && (
                                <li key={index}>
                                    <Link to="#" className="text-muted">
                                        {String(value)}
                                    </Link>
                                </li>
                            )
                        ))
                    ) : (
                        <li>No items available.</li>
                    )}
                </ul>
                                            <div className="mt-3">
                                                <Link to="#" className="fw-medium link-effect" onClick={() => { setShow(true); setInfo(item) }}>Read More <i className="ri-arrow-right-line align-bottom ms-1"></i></Link>
                                            </div>
                                            <img src={item.categoryImg} alt="" className="img-fluid category-img object-fit-cover" />
                                        </Card.Body>
                                    </Card>
                                </Col>))}
                            </Row>

                            {pagination && <Row id="pagination-element" className='mb-4'>
                                <Col lg={12}>
                                    <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                                        <div className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
                                            <Button variant="link" href="#" className="page-link" id="page-prev" onClick={() => handleprevPage()}>←</Button>
                                        </div>
                                        <span id="page-num" className="pagination">
                                            {pageNumbers.map((item: any, key: any) => (
                                                <React.Fragment key={key}>
                                                    <div className={currentPage === item ? "page-item active" : "page-item"}>
                                                        <Link className="page-link clickPageNumber" to="#" key={key} id={item} onClick={(e) => handleClick(e)}>
                                                            {item}
                                                        </Link>
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </span>
                                        <div className={currentPage >= pageNumbers.length ? "page-item disabled" : "page-item"}>
                                            <Button variant="link" href="#" className="page-link" id="page-next" onClick={() => handlenextPage()}>→</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>}

                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={showModal} onHide={handleClose}>
            <Modal.Header className="px-4 pt-4" closeButton>
                <h5 className="modal-title">Edit Category</h5>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit} className="tablelist-form">
                <Modal.Body className="p-4">
                    <div id="alert-error-msg" className="d-none alert alert-danger py-2"></div>
                    <Row>
                        {/* <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="palette">
                                Palette<sup>*</sup>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="palette"
                                    id="palette"
                                    placeholder="Enter palette"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.palette}
                                    isInvalid={formik.touched.palette && !!formik.errors.palette}
                                />
                                {formik.touched.palette && formik.errors.palette ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col> */}

<Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="palette">
        Palette
        </Form.Label>
        <Form.Select
            name="palette"
            id="palette"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.palette}
            isInvalid={formik.touched.palette && !!formik.errors.palette}
        >
           <option value="Cement">Cement</option>
                            <option value="Purple">Purple</option>
                            <option value="Green">Green</option>
                            <option value="Pink">Pink</option>
                            <option value="Red">Red</option>
                            <option value="Orange">Orange</option>

       
        </Form.Select>
        {formik.touched.palette && formik.errors.palette ? (
            <Form.Control.Feedback type="invalid">
                {formik.errors.palette}
            </Form.Control.Feedback>
        ) : null}
    </div>
</Col>


<Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="art_type
">
        Art Type

        </Form.Label>
        <Form.Select
            name="art_type"
            id="art_type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.art_type}
            isInvalid={formik.touched.art_type && !!formik.errors.art_type}
        >
              <option value="Roll form">Roll form</option>
                            <option value="Stretched canvas">Stretched canvas</option>
                            <option value="On hard board">On hard board</option>
                            <option value="Pre framed">Pre framed</option>

       
        </Form.Select>
        {formik.touched.palette && formik.errors.palette ? (
            <Form.Control.Feedback type="invalid">
                {formik.errors.palette}
            </Form.Control.Feedback>
        ) : null}
    </div>
</Col>


                        <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="colors">
                                Colors
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="colors"
                                    id="colors"
                                    placeholder="Enter colors"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.colors}
                                    isInvalid={formik.touched.colors && !!formik.errors.colors}
                                />
                                {formik.touched.colors && formik.errors.colors ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col>
                        {/* <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="art_subject">
                                Art Subject<sup>*</sup>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="art_subject"
                                    id="art_subject"
                                    placeholder="Enter art_subject"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.art_subject}
                                    isInvalid={formik.touched.art_subject && !!formik.errors.art_subject}
                                />
                                {formik.touched.art_subject && formik.errors.art_subject ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col> */}


<Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="art_subject">
        Art Subject
        </Form.Label>
        <Form.Select
            name="art_subject"
            id="art_subject"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.art_subject}
            isInvalid={formik.touched.art_subject && !!formik.errors.art_subject}
        >
           <option value="Abstract">Abstract</option>
                            <option value="Animals">Animals</option>
                            <option value="Architecture">Architecture</option>
                            <option value="Birds">Birds</option>
                            <option value="Botanical">Botanical</option>
                            <option value="Children">Children</option>
                            <option value="City/Cityscape">City/Cityscape</option>
                            <option value="Dance">Dance</option>
                            <option value="Dream">Dream</option>
                            <option value="Ethnic">Ethnic</option>
                            <option value="Family">Family</option>
                            <option value="Fish">Fish</option>

        </Form.Select>
        {formik.touched.art_subject && formik.errors.art_subject ? (
            <Form.Control.Feedback type="invalid">
                {formik.errors.art_subject}
            </Form.Control.Feedback>
        ) : null}
    </div>
</Col>
                        {/* <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="surface">
                                Surface<sup>*</sup>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="surface"
                                    id="surface"
                                    placeholder="Enter surface"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.surface}
                                    isInvalid={formik.touched.surface && !!formik.errors.surface}
                                />
                                {formik.touched.surface && formik.errors.surface ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col> */}


<Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="surface">
        Surface
        </Form.Label>
        <Form.Select
            name="surface"
            id="surface"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.surface}
            isInvalid={formik.touched.surface && !!formik.errors.surface}
        >
           			  <option value="100_acid_free_reg_paper">100% Acid free Reg paper</option>
	    		

                <option value="acrylic_sheet">Acrylic Sheet</option>
                  
  
                <option value="arches_paper">Arches paper</option>
                  
  
                <option value="board">Board</option>
                  
  
                <option value="canvas">Canvas</option>
                  
  
                <option value="handmade paper">Handmade Paper </option>
                  
  
                <option value="wooden_canvas">Wooden Canvas</option>
                  
  
                <option value="Watercolor Paper">Watercolor Paper</option>
                  
  
                <option value="fabriano_paper">Fabriano Paper</option>
                  
  
                <option value="art_card_paper">Art Card Paper</option>
                  
  
                <option value="fibreglass">Fibreglass</option>
                  
  
  
        </Form.Select>
        {formik.touched.surface && formik.errors.surface ? (
            <Form.Control.Feedback type="invalid">
                {formik.errors.surface}
            </Form.Control.Feedback>
        ) : null}
    </div>
</Col>
                        

<Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="category_id">
        Category Name
        </Form.Label>
        <Form.Select
            name="category_id"
            id="category_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.category_id}
            isInvalid={formik.touched.category_id && !!formik.errors.category_id}
        >
           <option value="">Select Category</option>
            {[
                "Painting",
                "Sculpture",
                "Limited Edition Prints",
                "Drawing",
                "Photograph",
                "Digital Art",
                "Art Collectibles",
                "Mixed Media",
                "Installation",
                "Serigraph",
                "Print Making",
            ].map((category) => (
                <option 
                    key={category} 
                    value={category}
                >
                    {category}
                </option>
            ))}

           
        </Form.Select>
        {formik.touched.category_id && formik.errors.category_id ? (
            <Form.Control.Feedback type="invalid">
                {formik.errors.category_id}
            </Form.Control.Feedback>
        ) : null}
    </div>
</Col>
                        <Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="shipped_as">
            Shipped As
        </Form.Label>
        <Form.Select
            name="shipped_as"
            id="shipped_as"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.shipped_as}
            isInvalid={formik.touched.shipped_as && !!formik.errors.shipped_as}
        >
          <option value="Framed">Framed</option>
	    		

                <option value="Rolled">Rolled</option>
                  
  
                <option value="Stretched">Stretched</option>
                  
  
                <option value="Crate">Crate</option>
  
           
        </Form.Select>
        {formik.touched.shipped_as && formik.errors.shipped_as ? (
            <Form.Control.Feedback type="invalid">
                {formik.errors.shipped_as}
            </Form.Control.Feedback>
        ) : null}
    </div>
</Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="year_released">
                                Year Released
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="year_released"
                                    id="last_name"
                                    placeholder="Enter year_released"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.year_released}
                                    isInvalid={formik.touched.year_released && !!formik.errors.year_released}
                                />
                                {formik.touched.year_released && formik.errors.year_released ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="timelines">
                                Timelines
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="timelines"
                                    id="timelines"
                                    placeholder="Enter timelines"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.timelines}
                                    isInvalid={formik.touched.timelines && !!formik.errors.timelines}
                                />
                                {formik.touched.timelines && formik.errors.timelines ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="edition_size">
                                Edition Size
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="edition_size"
                                    id="edition_size"
                                    placeholder="Enter edition_size"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.art_type}
                                    isInvalid={formik.touched.edition_size && !!formik.errors.edition_size}
                                />
                                {formik.touched.edition_size && formik.errors.edition_size ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="signature">
                                Signature<sup>*</sup>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="signature"
                                    id="signature"
                                    placeholder="Enter signature"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.signature}
                                    isInvalid={formik.touched.signature && !!formik.errors.signature}
                                />
                                {formik.touched.signature && formik.errors.signature ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="tags">
                                Tags
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="tags"
                                    id="tags"
                                    placeholder="Enter tags"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tags}
                                    isInvalid={formik.touched.tags && !!formik.errors.tags}
                                />
                                {formik.touched.tags && formik.errors.tags ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col>
                        {/* <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="mediums">
                                Mediums<sup>*</sup>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mediums"
                                    id="mediums"
                                    placeholder="Enter mediums"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.mediums}
                                    isInvalid={formik.touched.mediums && !!formik.errors.mediums}
                                />
                                {formik.touched.mediums && formik.errors.mediums ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col> */}


<Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="mediums">
        Mediums
        </Form.Label>
        <Form.Select
            name="mediums"
            id="mediums"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mediums}
            isInvalid={formik.touched.mediums && !!formik.errors.mediums}
        >
              <option value="Pen and Ink">Pen and Ink</option>
                            <option value="Acrylic">Acrylic</option>
                            <option value="Encaustic">Encaustic</option>
                            <option value="Gouache">Gouache</option>
                            <option value="Egg Tempera">Egg Tempera</option>
                            <option value="Mixed Media">Mixed Media</option>
                            <option value="Oil">Oil</option>
                            <option value="Watercolour and Gouache">Watercolour and Gouache</option>
                            <option value="Acrylic and Oil">Acrylic and Oil</option>
                            <option value="Watercolour and Acrylic">Watercolour and Acrylic</option>
                            <option value="Tempera">Tempera</option>
                            <option value="Watercolor">Watercolor</option>

        </Form.Select>
        {formik.touched.mediums && formik.errors.mediums ? (
            <Form.Control.Feedback type="invalid">
                {formik.errors.mediums}
            </Form.Control.Feedback>
        ) : null}
    </div>
</Col>

                        {/* <Col md={6}>
                            <div className="mb-3">
                                <Form.Label htmlFor="art_style">
                                Art Style<sup>*</sup>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="art_style"
                                    id="art_style"
                                    placeholder="Enter art_style"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.art_style}
                                    isInvalid={formik.touched.art_style && !!formik.errors.art_style}
                                />
                                {formik.touched.art_style && formik.errors.art_style ? (
                                    <Form.Control.Feedback type="invalid">
                                        
                                    </Form.Control.Feedback>
                                ) : null}
                            </div>
                        </Col> */}

<Col md={6}>
    <div className="mb-3">
        <Form.Label htmlFor="art_style">
        Art Style
        </Form.Label>
        <Form.Select
            name="art_style"
            id="art_style"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.art_style}
            isInvalid={formik.touched.art_style && !!formik.errors.art_style}
        >
           
           <option value="Abstract">Abstract</option>
                            <option value="Figurative">Figurative</option>
                            <option value="Folk">Folk</option>
                            <option value="Decorative">Decorative</option>
                            <option value="Cubism">Cubism</option>
                            <option value="Realism">Realism</option>
                            <option value="Impressionism">Impressionism</option>
                            <option value="Expressionism">Expressionism</option>
                            <option value="Minimalism">Minimalism</option>

            {/* Add more options as needed */}
        </Form.Select>
        {formik.touched.art_style && formik.errors.art_style ? (
            <Form.Control.Feedback type="invalid">
                {formik.errors.art_style}
            </Form.Control.Feedback>
        ) : null}
    </div>
</Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                        Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Save Changes
                    </button>
                </Modal.Footer>
            </Form>
        </Modal>

            <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>#TB12</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="avatar-lg mx-auto">
                        <div className="avatar-title bg-light rounded">
                            <img src={info.categoryImg} alt="" className="avatar-sm overview-img" />
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <h5 className="overview-title">{info.categoryTitle}</h5>
                        <p className="text-muted">by <Link to="#" className="text-reset">Admin</Link></p>
                    </div>

                    <h6 className="fs-14">Descon</h6>
                    <p className="text-muted overview-desc">{info.description}</p>

                    <h6 className="fs-14 mb-3">Sub Categories</h6>
                    <ul className="vstack gap-2 mb-0 subCategory" style={{ listStyleType: "circle" }}>
                        {(info.subCategory || []).map((item: any, key: number) => (key < 4 && <li key={key}><Link to="#" className="text-reset">{item}</Link></li>))}
                    </ul>
                </Offcanvas.Body>
                <div className="p-3 border-top">
                    <Row>
                        <Col sm={6}>
                            <div data-bs-dismiss="offcanvas">
                                <Button variant="danger" type="button" className="btn btn-danger w-100 remove-list" data-bs-toggle="modal" data-bs-target="#delteModal" data-remove-id="12"><i className="ri-delete-bin-line me-1 align-bottom"></i> Delete</Button>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <Button variant="secondary" type="button" className="w-100 edit-list" data-bs-dismiss="offcanvas" data-edit-id="12"><i className="ri-pencil-line me-1 align-bottom"></i> Edit</Button>
                        </Col>
                    </Row>
                </div>
            </Offcanvas>
            <Modal show={modal_DeleteConfirm} onHide={() => setModalDeleteConfirm(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this item?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalDeleteConfirm(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger"  onClick={() => handleDeleteConfirm(selectedItem)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >

    );
}

export default Categories;