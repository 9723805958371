import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import Flatpickr from "react-flatpickr";
import axios from 'axios';


interface CreateProductStep1Props {
    formik: any;
}

const CreateProductStep1: React.FC<CreateProductStep1Props> = ({ formik }) => {
    document.title = "Create Product | Artflute + Admin React Template";
    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = formik;
    const [getArtist, setGetArtist] = useState<{ value: string, label: string }[]>([]);

    useEffect(() => {
        const fetchArtists = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_all_artist_name`);
                console.log("kk", response.data)
                if (response.data && Array.isArray(response.data) && response.data[0] !== null) {
                    const artists = response.data.map((artist: { id: string, name: string }) => ({
                        value: artist.id,
                        label: artist.name
                    }));
                    setGetArtist(artists);
                } else {
                    console.warn("No artist data available");
                }
            } catch (error) {
                console.error("Error fetching artist names", error);
            }
        };

        fetchArtists();
    }, []);
    console.log('getArtist', getArtist)

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files[0]) {
            try {
                let data: any = new FormData();
                data.append('fileType', 'artist_thumb_desktop');
                data.append('filePath', event.target.files[0]);

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data.mediaPaths[0].filePath));
                        setFieldValue(fieldName, response.data.mediaPaths[0].filePath);
                        console.log('imageData', response.data.mediaPaths[0].filePath)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Error on upload image ", error);
            }
        }
    };

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle bg-light text-primary fs-20">
                                                        <i className="bi bi-box-seam"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">Artwork Information</h5>
                                                <p className="text-muted mb-0">Fill all information below.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group className='mt-3' controlId="artist_id">
                                            <Form.Label>Artist</Form.Label>
                                            <Form.Select
                                                name="artist_id"
                                                value={values.artist_id || ""}
                                                onChange={handleChange}
                                                isInvalid={!!errors.artist_id && touched.artist_id}
                                            >
                                                <option value="">Select</option>
                                                {getArtist.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.artist_id}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='mt-3' controlId="Title">
                                            <Form.Label>Title <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Title"
                                                placeholder="Enter Title"
                                                value={values.Title}
                                                onChange={handleChange}
                                                isInvalid={!!errors.Title && touched.Title}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Title}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='mt-3' controlId="Slug">
                                            <Form.Label>Slug </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Slug"
                                                placeholder="Enter Slug"
                                                value={values.Slug}
                                                onChange={handleChange}
                                                isInvalid={!!errors.Slug && touched.Slug}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Slug}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='mt-3' controlId="Label">
                                            <Form.Label>Label </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Label"
                                                placeholder="Enter Label"
                                                value={values.Label}
                                                onChange={handleChange}
                                                isInvalid={!!errors.Label && touched.Label}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Label}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='mt-3' controlId="Description">
                                            <Form.Label>Description </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="Description"
                                                placeholder="Description"
                                                value={values.Description}
                                                onChange={handleChange}
                                                isInvalid={!!errors.Description && touched.Description}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.Description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle bg-light text-primary fs-20">
                                                        <i className="bi bi-images"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">Artwork Gallery</h5>
                                                <p className="text-muted mb-0">Add Artwork gallery images.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Row>
                                        {/* Artwork Image */}
                                        <Col md={6} sm={6} xs={12}>
                                            <Form.Group className='mt-3 ms-3 ps-1' controlId="Artwork_image">
                                                <Form.Label>Artwork image  <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    name="Artwork_image"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "Artwork_image")}
                                                    isInvalid={!!errors.Artwork_image && touched.Artwork_image}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.Artwork_image}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {/* Artwork Webp Image */}
                                        <Col md={6} sm={6} xs={12}>
                                            <Form.Group className='mt-3 me-3 pe-1' controlId="Artwork_webp_image">
                                                <Form.Label>Artwork webp image</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    name="Artwork_webp_image"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "Artwork_webp_image")}
                                                    isInvalid={!!errors.Artwork_webp_image && touched.Artwork_webp_image}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.Artwork_webp_image}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {/* Artwork Full Image */}
                                        <Col md={6} sm={6} xs={12}>
                                            <Form.Group className='mt-3 ms-3 ps-1 mb-3' controlId="Artwork_full_image">
                                                <Form.Label>Artwork full image  <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    name="Artwork_full_image"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "Artwork_full_image")}
                                                    isInvalid={!!errors.Artwork_full_image && touched.Artwork_full_image}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.Artwork_full_image}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {/* Artwork Full Webp Image */}
                                        <Col md={6} sm={6} xs={12}>
                                            <Form.Group className='mt-3 me-3 pe-1 mb-3 ' controlId="Artwork_full_webp_image">
                                                <Form.Label>Artwork Full Webp Image</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    name="Artwork_full_webp_image"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "Artwork_full_webp_image")}
                                                    isInvalid={!!errors.Artwork_full_webp_image && touched.Artwork_full_webp_image}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.Artwork_full_webp_image}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle bg-light text-primary fs-20">
                                                        <i className="bi bi-list-ul"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h5 className="card-title mb-1">General Information</h5>
                                                <p className="text-muted mb-0">Fill all information below.</p>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={6} sm={6}>
                                                <Form.Group className='mt-3' controlId="Status">
                                                    <Form.Label>Status </Form.Label>
                                                    <Form.Select
                                                        name="Status"
                                                        value={values.Status || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Status && touched.Status}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="0">Waiting for approval</option>
                                                        <option value="1">Approved</option>
                                                        <option value="2">Sold Out</option>
                                                        <option value="3">Disabled</option>
                                                        <option value="4">Deleted</option>
                                                        <option value="5">Not approved</option>
                                                        <option value="7">Enable</option>
                                                        <option value="6">Available For Commission Work</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Status}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className='mt-3' controlId="sold_out">
                                                    <Form.Label>Sold out? </Form.Label>
                                                    <Form.Select
                                                        name="sold_out"
                                                        value={values.sold_out || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.sold_out && touched.sold_out}
                                                    >
                                                        <option value="">Select</option>
                                                        <option defaultValue="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.sold_out}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Weight">
                                                    <Form.Label>Weight(in Kg) </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Weight"
                                                        placeholder="Enter weight"
                                                        value={values.Weight}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Weight && touched.Weight}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Weight}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Dimension_depth">
                                                    <Form.Label>Dimension Depth </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Dimension_depth"
                                                        placeholder="Enter dimension depth"
                                                        value={values.Dimension_depth}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Dimension_depth && touched.Dimension_depth}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Dimension_depth}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={6}>
                                                <Form.Group className='mt-3' controlId="Originals_price">
                                                    <Form.Label>Originals price </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Originals_price"
                                                        placeholder="Enter Originals price"
                                                        value={values.Originals_price}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Originals_price && touched.Originals_price}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Originals_price}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Originals_price_dollar">
                                                    <Form.Label>Originals price dollar </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Originals_price_dollar"
                                                        placeholder="Enter Originals price dollar"
                                                        value={values.Originals_price_dollare}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Originals_price_dollar && touched.Originals_price_dollar}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Originals_price_dollar}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Dimension_width">
                                                    <Form.Label>Dimension Width <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Dimension_width"
                                                        placeholder="Enter Dimension Width"
                                                        value={values.Dimension_width}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Dimension_width && touched.Dimension_width}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Dimension_width}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Dimension_height">
                                                    <Form.Label>Dimension Height<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Dimension_height"
                                                        placeholder="Enter Dimension height"
                                                        value={values.Dimension_height}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Dimension_height && touched.Dimension_height}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Dimension_height}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={6}>

                                                <Form.Group className='mt-3' controlId="Is_available_for_print_sale">
                                                    <Form.Label>Is Available for print sale?</Form.Label>
                                                    <Form.Select
                                                        name="Is_available_for_print_sale"
                                                        value={values.Is_available_for_print_sale || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Is_available_for_print_sale && touched.Is_available_for_print_sale}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Is_available_for_print_sale}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className='mt-3' controlId="Month">
                                                    <Form.Label>Month</Form.Label>
                                                    <Form.Select
                                                        name="Month"
                                                        value={values.Month || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Month && touched.Month}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="jan">January</option>
                                                        <option value="feb">February</option>
                                                        <option value="mar">March</option>
                                                        <option value="apr">April</option>
                                                        <option value="may">May</option>
                                                        <option value="jun">June</option>
                                                        <option value="jul">July</option>
                                                        <option value="aug">August</option>
                                                        <option value="sep">September</option>
                                                        <option value="oct">October</option>
                                                        <option value="nov">November</option>
                                                        <option value="dec">December</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Month}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Limited_period_discount">
                                                    <Form.Label>Limited period discount</Form.Label>
                                                    <Form.Select
                                                        name="Limited_period_discount"
                                                        value={values.Limited_period_discount || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Limited_period_discount && touched.Limited_period_discount}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Limited_period_discount}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Label_for_date">
                                                    <Form.Label>Label For Date</Form.Label>
                                                    <Flatpickr
                                                        className={`form-control flatpickr-input ${touched.Label_for_date && errors.Label_for_date ? 'is-invalid' : ''
                                                            }`}
                                                        placeholder='Enter publish date'
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                        }}
                                                        onChange={(date) => {
                                                            setFieldValue('Label_for_date', date[0]);
                                                        }}
                                                    />
                                                    {touched.Label_for_date && errors.Label_for_date && (
                                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                                            {errors.Label_for_date}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Price_on_request">
                                                    <Form.Label>Price On Request <span className="text-danger">*</span></Form.Label>
                                                    <Form.Select
                                                        name="Price_on_request"
                                                        value={values.Price_on_request || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Price_on_request && touched.Price_on_request}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Price_on_request}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={6}>
                                                <Form.Group className='mt-3' controlId="Is_vailable_for_desktop_art">
                                                    <Form.Label>Is Available for desktop art?</Form.Label>
                                                    <Form.Select
                                                        name="Is_vailable_for_desktop_art"
                                                        value={values.Is_vailable_for_desktop_art || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Is_vailable_for_desktop_art && touched.Is_vailable_for_desktop_art}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Is_vailable_for_desktop_art}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Year">
                                                    <Form.Label>Year </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Year"
                                                        placeholder="Enter Year"
                                                        value={values.Year}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Year && touched.Year}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Year}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Creation_place">
                                                    <Form.Label>Creation place</Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name="Creation_place"
                                                        placeholder="Enter Creation place"
                                                        value={values.Creation_place}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Creation_place && touched.Creation_place}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Creation_place}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mt-3' controlId="Orientation">
                                                    <Form.Label>Orientation</Form.Label>
                                                    <Form.Select
                                                        name="Orientation"
                                                        value={values.Orientation || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Orientation && touched.Orientation}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">square</option>
                                                        <option value="Hidden">verticle</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Orientation}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                {/* <Form.Group className='mt-3' controlId="Price_on_request">
                                                    <Form.Label>Price On Request <span className="text-danger">*</span></Form.Label>
                                                    <Form.Select
                                                        name="Price_on_request"
                                                        value={values.Price_on_request || ""}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.Price_on_request && touched.Price_on_request}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Public">no</option>
                                                        <option value="Hidden">yes</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.Price_on_request}
                                                    </Form.Control.Feedback>
                                                </Form.Group> */}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment >
    );
}

export default CreateProductStep1;