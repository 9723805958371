import React, { useState, useEffect, useMemo } from 'react';
import { Button, Card, Col, Container, Dropdown, Row, Modal, Form, Alert } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Flatpickr from "react-flatpickr";
import dropdown from 'assets/images/dropdown.png'
import deleteicon from 'assets/images/delete icon.png';
import NoImage from 'assets/images/no-image.png'

interface SellersGridProps {
    LinkToSetting?: any;
}


const ArtistGridView: React.FC<SellersGridProps> = ({ LinkToSetting }) => {

    document.title = "Grid View - Sellers | Artflute + Admin React Template";

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [pagination, setPagination] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPages, setCurrentPages] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [sellerList, setSellerList] = useState<any[]>([]);
    const [sellerGrid, setSellerGrid] = useState<any[]>([]);

    const [sellelist, settestartist] = useState<any[]>([]);
    const [dateFilter, setDateFilter] = useState('All');
    const [editusers, seteditusers] = useState<boolean>(false);
    const [selectedSeller, setSelectedSeller] = useState<any>(null);
    const [statusdate, setstatusdate] = useState<any>([]);;

    const [selecteartist, setdeletedartist] = useState<any>(null);
    const [modal_DeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
    const [galleries, setGalleries] = useState([{ name: '' }]);

    const perPageData = 8;
    const navigate = useNavigate();
    const [deleteSellerId, setDeleteSellerId] = useState<any>(null); // State to store the seller ID for deletion
    const getToken = () => {
        const tokenString = localStorage.getItem('authUser');
        if (tokenString) {
            const tokenObject = JSON.parse(tokenString);
            return tokenObject.token || '';
        }
        return '';
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            validation.setFieldValue('Profile_Image', event.target.files[0]);
        }
    };
  
  
    // useEffect(() => {
    const fetchSellerList = async () => {
        setLoader(true);
        try {
            const token = getToken();
            const queryParams = new URLSearchParams({
                date: dateFilter === 'All' ? '' : dateFilter.toLowerCase()
            }).toString();
            const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_artist_list?${queryParams}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            settestartist(response.data.users);
            const processedGrid = response.data.users?.map((item: any) => ({
                id: item.user.id,
                first_name: item.user.first_name,
                Gender: item.user.Gender,
                status: item.user.users_status,
                created_at: item.user.created_at,
            })) || [];

            setSellerGrid(processedGrid);
        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };



    useEffect(() => {
        fetchSellerList();
    }, [dateFilter]);
    useEffect(() => {
        const indexOfLast = currentPage * perPageData;
        const indexOfFirst = indexOfLast - perPageData;

        const currentdata = sellerGrid.slice(indexOfFirst, indexOfLast);
        setCurrentPages(currentdata);
    }, [currentPage, sellerGrid]);

    const handleClick = (e: any) => {
        setCurrentPage(Number(e.target.id));
    };

    const currentdata = useMemo(() => {
        const indexOfLast = currentPage * perPageData;
        const indexOfFirst = indexOfLast - perPageData;
        return sellerGrid.slice(indexOfFirst, indexOfLast);
    }, [currentPage, sellerGrid]);

    useEffect(() => {
        setCurrentPages(currentdata);
    }, [currentPage, currentdata]);

    const searchTeamMember = (ele: any) => {
        let search = ele.target.value.toLowerCase();

        if (search) {
            setCurrentPages(
                sellerGrid.filter((data: any) => {
                    return Object.values(data).some((value) =>
                        value?.toString().toLowerCase().includes(search)
                    );
                })
            );
            setPagination(false);
        } else {
            setCurrentPages(currentdata);
            setPagination(true);
        }
    };

    const pageNumbers: any = [];
    for (let i = 1; i <= Math.ceil(sellerGrid.length / perPageData); i++) {
        pageNumbers.push(i);
    }

    const handlePrevPage = () => {
        let prevPage = currentPage - 1;
        setCurrentPage(prevPage);
    };

    const handleNextPage = () => {
        let nextPage = currentPage + 1;
        setCurrentPage(nextPage);
    };

    useEffect(() => {
        if (pageNumbers.length && pageNumbers.length < currentPage) {
            setCurrentPage(pageNumbers.length);
        }
    }, [currentPage, pageNumbers.length]);

    const [modal_AddSellerModals, setModal_AddSellerModals] = useState<boolean>(false);
    const toggleAddSellerModals = () => {
        setModal_AddSellerModals(!modal_AddSellerModals);
    };

    const fetchPartnersDetailsById = async (id: any) => {
        console.log("Selected ID:", id);

        setLoader(true);
        try {
            const token = getToken();
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_artists_by_id`,
                {
                    params: { id },
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );

            const processedData = response.data.users?.map((item: any) => ({
                id: item.id,
                first_name: item.first_name,
                last_name: item.last_name,
                email: item?.email || '',
                status: item.user_status,
            })) || [];
            console.log(processedData, "processedData")
            navigate(`/recent-artist?id=${id}`, { state: { yy: processedData } });
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };

    const handleDateFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDateFilter(event.target.value);
    };
    console.log(sellelist, "sellerGridsellerGridsellerGridsellerGridsellerGrid")
    const sellerToEdit = sellelist.find((seller: any) => seller.user.id)
    console.log(sellerToEdit, "9999999999999999999999999999999999999")
    const handleEditClick = (id: any) => {
        if (sellelist.length === 0) {
            console.log("No sellers available to edit.");
            return;
        }

        const sellerToEdit = sellelist.find((seller: any) => seller.user.id === id);
        if (sellerToEdit) {

            setSelectedSeller(sellerToEdit);
        } else {
            console.log("Seller not found.");
        }
    };
    const handleGalleryChange = (index: any, event: any) => {
        const newGalleries = [...galleries];
        newGalleries[index].name = event.target.value;
        setGalleries(newGalleries);
        validation.setFieldValue('Association_with_art_galleries', newGalleries);
    };

    const handleAddGallery = () => {
        const updatedGalleries = [...galleries, { name: '' }];
        setGalleries(updatedGalleries);
        validation.setFieldValue('Association_with_art_galleries', updatedGalleries.map(g => g.name).join(','));
    };

    const handleRemoveGallery = (index: any) => {
        const updatedGalleries = galleries.filter((_, i) => i !== index);
        setGalleries(updatedGalleries);
        validation.setFieldValue('Association_with_art_galleries', updatedGalleries.map(g => g.name).join(','));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: selectedSeller?.user.id || '',
            last_name: selectedSeller?.user?.last_name || '',
            email: selectedSeller?.user?.email || '',
            first_name: selectedSeller?.user?.first_name || '',
            status: selectedSeller?.user?.status || '',



            Date_Of_Birth: selectedSeller?.user?.Date_Of_Birth || '',
            Gender: selectedSeller?.user?.Gender || '',
            Phone_number: selectedSeller?.user?.Phone_number || '',
            Profile_Image: null,
            Telephone_number: selectedSeller?.user?.Telephone_number || '',
            Address: selectedSeller?.user?.Address || '',
            City: selectedSeller?.user?.City || '',
            State: selectedSeller?.user?.State || '',
            Zipcode: selectedSeller?.user?.Zipcode || '',
            Country: selectedSeller?.user?.Country || '',
            designation: selectedSeller?.user?.designation || '',
            Blog_URL: selectedSeller?.user?.Blog_URL || '',
            User: selectedSeller?.user?.User || '',
            Seo_Title: selectedSeller?.user?.Seo_Title || '',
            Seo_Description: selectedSeller?.user?.Seo_Description || '',
            Selection_of_Enquire_for_Orginals: selectedSeller?.user?.Selection_of_Enquire_for_Orginals || '',
            Commission_work_for_This_Artist: selectedSeller?.user?.Commission_work_for_This_Artist || '',
            Artist_thumbnail_desktop_Image: selectedSeller?.user?.Artist_thumbnail_desktop_Image || '',
            Artist_thumbnail_Mobile_Image: selectedSeller?.user?.Artist_thumbnail_Mobile_Image || '',
            Photo_Essay_Title: selectedSeller?.user?.Photo_Essay_Title || '',
            Photo_Essay_Description: selectedSeller?.user?.Photo_Essay_Description || '',
            Essay_Title_1: selectedSeller?.user?.Essay_Title_1 || '',
            Essay_Image_One: selectedSeller?.user?.Essay_Image_One || '',
            Essay_One_Description: selectedSeller?.user?.Essay_One_Description || '',
            Essay_Title_2: selectedSeller?.user?.Essay_Title_2 || '',
            Essay_Image_Two: selectedSeller?.user?.Essay_Image_Two || '',
            Essay_Two_Description: selectedSeller?.user?.Essay_Two_Description || '',
            Essay_Image_Three: selectedSeller?.user?.Essay_Image_Three || '',
            Essay_Three_Description: selectedSeller?.user?.Essay_Three_Description || '',
            Essay_Title_4: selectedSeller?.user?.Essay_Title_4 || '',
            Essay_Image_Four: selectedSeller?.user?.Essay_Image_Four || '',
            Essay_Four_Description: selectedSeller?.user?.Essay_Four_Description || '',
            Essay_Title_5: selectedSeller?.user?.Essay_Title_5 || '',
            Essay_Image_Five: selectedSeller?.user?.Essay_Image_Five || '',
            Essay_Five_Description: selectedSeller?.user?.Essay_Five_Description || '', // Corrected this line
            Essay_Title_6: selectedSeller?.user?.Essay_Title_6 || '',
            Essay_Image_Six: selectedSeller?.user?.Essay_Image_Six || '',
            Essay_Six_Description: selectedSeller?.user?.Essay_Six_Description || '',
            Essay_Title_Three: selectedSeller?.user?.Essay_Six_Description || '',

        },
        validationSchema: Yup.object({
            // first_name: Yup.string().required('First name is required'),
            // last_name: Yup.string().required('Last name is required'),

        }),
        onSubmit: async (values) => {
            console.log(values, "55555");
            setLoader(true);
            try {
                const formData = new FormData();
                Object.keys(values).forEach(key => {
                    if (key === 'Profile_Image' && values[key]) {
                        formData.append(key, values[key]);
                    } else {
                        formData.append(key, (values as any)[key]);
                    }
                });


                const token = getToken();

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/edit_artist`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                seteditusers(false);
                setSelectedSeller(null);


                validation.resetForm();
                fetchSellerList();
                setShowSuccessAlert(true); // Show success alert
                setTimeout(() => setShowSuccessAlert(false), 300000)
                    ;

            } catch (error) {
                console.error(error);
            } finally {
                setLoader(false);
            }
        },
    });




    const tokenString = localStorage.getItem('authUser');
    let token = '';

    if (tokenString) {
        const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
        token = tokenObject.token;
    }


    const toggleDeleteConfirmModal = (id: any) => {
        setDeleteSellerId(id);
        setModalDeleteConfirm(!modal_DeleteConfirm);
    };

    const confirmDelete = async (id: any) => {
        try {
            setLoader(true);
            await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/delete_artist_details`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    params: { id: id },
                }
            );
            setdeletedartist(null);


            setModalDeleteConfirm(false); // Close the modal after deletion
            fetchSellerList();
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
    };

    const fetchStatusanddateList = async () => {
        setLoader(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_dates_status`,
                {

                }
            );
            setstatusdate(response.data);
        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchStatusanddateList();
    }, []);

    const formatDate = (dateString: any) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Artist" pageTitle="Artist" />

                    <Row className="mb-4">
                        <Col xxl={12}>
                            <Card className="text-center bg-primary border-0 mb-0">
                                <Card.Body className="p-5">
                                    <h4 className="text-white">Search for Artist</h4>
                                    <p className="text-center text-white-75 fs-17 mb-3">when you'r searching for a Artist's, then are a things you can do to get the most out of your search</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={6} md={10} className="mx-auto">
                            <form action="#!" className="mt-n4">
                                <div className="seller-search-box position-relative">
                                    <i className="ri-search-2-line position-absolute my-auto d-flex align-items-center"></i>
                                    <input type="text" className="form-control rounded-pill border-0 shadow" id="searchInputList" autoComplete="off" placeholder="Search ..." onChange={(e) => searchTeamMember(e)} />
                                    {/* <Button variant='soft-danger' className="fw-normal position-absolute rounded-pill"><i className="ri-map-pin-2-line align-bottom me-1"></i> Germany</Button> */}
                                </div>
                            </form>
                        </Col>
                    </Row>

                    <Row className="mb-4 justify-content-between">
                        <Col xxl={2} sm={6}>
                            <Link to={LinkToSetting}>
                                <Button onClick={() => toggleAddSellerModals()} className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#createModal"><i className="ri-add-line align-bottom me-1"></i>Add Artist</Button>
                            </Link>
                        </Col>
                        <Col xxl={2} sm={6}>
                            <select
                                className="form-select mt-3 mt-sm-0"
                                value={dateFilter}
                                onChange={handleDateFilterChange}
                            >


                                {statusdate?.dates?.map((status: any, index: number) => (
                                    <option key={index} value={status}>{status}</option>
                                ))}


                            </select>
                        </Col>
                    </Row>

                    {showSuccessAlert && (
                        <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                            Artist updated successfully!
                        </Alert>
                    )}
                    <Row id="seller-list">
                        {(currentPages || []).map((item: any, key: number) => (
                            <Col xxl={3} lg={6} key={key}>
                                <Card>
                                    <Card.Body className="p-4">
                                        <Row>
                                            <div className="bg-light rounded py-3 position-relative mb-2">
                                                <h5
                                                    style={{ right: '16px', top: "8px" }}
                                                    className={`mb-0 badge position-absolute ${item.status === 'Active' ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'} text-uppercase`}
                                                >
                                                    {item.status?.toLowerCase()}
                                                </h5>

                                                <img src={NoImage} alt="" style={{ maxHeight: "150px", maxWidth: "100%" }} className="mx-auto d-block rounded-2 my-3" />

                                                {parseFloat(item.discount) > 0 && <div className="avatar-xs label">
                                                    <div className="avatar-title bg-danger rounded-circle fs-11">{item.discount}</div>
                                                </div>}
                                            </div>
                                            <div className="col-12 mb-2">
                                                <div className="">
                                                    <p className="text-muted mb-2 fs-15">Name</p>
                                                    <h5 className="mb-0">{item.first_name}</h5>
                                                </div>

                                            </div>
                                            <div className="col-12 mb-2">
                                                <div className="">
                                                    <p className="text-muted mb-2 fs-15">Email</p>
                                                    <h5 className="mb-0">Xyz@gmail.com</h5>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <div className="">
                                                    <p className="text-muted mb-2 fs-15">Phone Number</p>
                                                    <h5 className="mb-0">9898989898</h5>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-2">
                                                <div className="">
                                                    <p className="text-muted mb-2 fs-15">created_at</p>
                                                    <h5 className="mb-0">{formatDate(item.created_at)}</h5>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-2">
                                                <div className="">
                                                    <p className="text-muted mb-2 fs-15">Gender</p>
                                                    <h5 className="mb-0">{item.Gender}</h5>
                                                </div>
                                            </div>
                                        </Row>

                                        <div className="mt-4 hstack gap-2">

                                            <Button
                                                variant="soft-secondary"
                                                className="w-100"
                                                onClick={() => fetchPartnersDetailsById(item.id)}
                                            >
                                                View Details
                                            </Button>

                                            <Dropdown className="flex-shrink-0">
                                                <Dropdown.Toggle className="btn btn-soft-info btn-icon arrow-none">
                                                    <i className="ph-dots-three-outline-vertical-fill"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu as="ul" className='p-0'>
                                                    <div className="edit">
                                                        <Button
                                                            onClick={() => handleEditClick(item.id)}
                                                            className="btn btn-sm btn-soft-info w-100"
                                                        >
                                                            Edit
                                                        </Button>
                                                    </div>
                                                    <div className="remove">
                                                        {item.status === 'Active' && (
                                                            <Button
                                                                variant="soft-danger"
                                                                size="sm"
                                                                className="remove-item-btn w-100"
                                                                onClick={() => toggleDeleteConfirmModal(item.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Card.Body>

                                </Card>
                            </Col>
                        ))}
                    </Row>
                   
                    {currentPages.length===0 && <div id="noresult">
                        <div className="text-center py-4">
                            <div className="avatar-md mx-auto mb-4">
                                <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                                    <i className="bi bi-search"></i>
                                </div>
                            </div>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                        </div>
                    </div>}
                    {currentPages.length > 0 && (
   pagination && (<Row className="mb-4" id="pagination-element">
   <Col lg={12}>
       <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
           <div className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
               <Button variant="link" href="#" className="page-link" id="page-prev" onClick={() => handlePrevPage()}><i className="mdi mdi-chevron-left" /></Button>
           </div>


           <span id="page-num" className="pagination">
                                    {pageNumbers.map((item: any, key: any) => (
                                        <React.Fragment key={key}>
                                            <div className={currentPage === item ? "page-item active" : "page-item"}>
                                                <Link className="page-link clickPageNumber" to="#" key={key} id={item} onClick={(e) => handleClick(e)}>
                                                    {item}
                                                </Link>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </span>
                                <div className={currentPage >= pageNumbers.length ? "page-item disabled" : "page-item"}>
                                    <Button variant="link" href="#" className="page-link" id="page-next" onClick={() => handleNextPage()}><i className="mdi mdi-chevron-right" /></Button>
                                </div>
                            </div>
                        </Col>
        </Row>
    )
)}

                </Container>
            </div>

            {/* Edit Seller Modal */}
            <Modal show={!!selectedSeller} onHide={() => setSelectedSeller(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Artist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={validation.handleSubmit}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="first_name"
                                value={validation.values.first_name}
                                onChange={validation.handleChange}
                                isInvalid={!!validation.errors.first_name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {/* {validation.errors.first_name} */}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="last_name"
                                value={validation.values.last_name}
                                onChange={validation.handleChange}
                                isInvalid={!!validation.errors.last_name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {/* {validation.errors.last_name} */}
                            </Form.Control.Feedback>
                        </Form.Group>



























                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="phoneInput">Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="phoneInput"
                                    name="Phone_number"
                                    placeholder="Enter phone number"
                                    value={validation.values.Phone_number}
                                    onChange={validation.handleChange}
                                    isInvalid={!!validation.errors.Phone_number}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {/* {validation.errors.Phone_number} */}
                                </Form.Control.Feedback>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="birthdateInput">Date of Birth</Form.Label>
                                <Flatpickr
                                    className="form-control flatpickr-input"
                                    placeholder='Select Date'
                                    options={{
                                        mode: "range",
                                        dateFormat: "d M, Y",
                                    }}
                                    onChange={(date) => validation.setFieldValue('date_of_birth', date)}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="designationInput">Designation</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="designationInput"
                                    name="designation"
                                    placeholder="Designation"
                                    value={validation.values.designation}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>














                        <Col lg={6}>

                            <div>
                                <Form.Label htmlFor="genderSelect">Gender</Form.Label>
                                <Form.Select
                                    id="GenderSelect"
                                    name="Gender"
                                    value={validation.values.Gender || ""}
                                    onChange={validation.handleChange}
                                    isInvalid={!!validation.errors.Gender}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {/* {validation.errors.Gender} */}
                                </Form.Control.Feedback>
                            </div>
                        </Col>










                        <Col lg={6}>

                            {/* <div>
                                       <Form.Label htmlFor="statusSelect">status</Form.Label>
                                       <Form.Select
                                           id="GenderSelect"
                                           name="status"
                                           value={validation.values.status || ""}
                                           onChange={validation.handleChange}
                                           isInvalid={!!validation.errors.status}
                                       >
                                           <option value="">Select status</option>
                                           <option value="Active">Active</option>
                                           <option value="Inactive">Inactive</option>
                                       </Form.Select>
                                       <Form.Control.Feedback type="invalid">
                                           {validation.errors.status}
                                       </Form.Control.Feedback>
                                   </div> */}





                        </Col>



                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="websiteInput1">Website</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="websiteInput1"
                                    name="website"
                                    placeholder="www.example.com"
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="cityInput">City</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="cityInput"
                                    name="City"
                                    placeholder="City"
                                    value={validation.values.City}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="countryInput">Country</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="countryInput"
                                    name="Country"
                                    placeholder="Country"
                                    value={validation.values.Country}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        {/* <Col lg={6}> */}
                        <div>
                            <Form.Label htmlFor="zipcodeInput">Zip Code</Form.Label>
                            <Form.Control
                                type="text"
                                id="zipcodeInput"
                                name="Zipcode"
                                placeholder="Enter zipcode"
                                value={validation.values.Zipcode}
                                onChange={validation.handleChange}
                            />
                        </div>
                        {/* </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Form.Label>About</Form.Label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                            />
                                        </div>
                                    </Col> */}
                        {/* <Col lg={12}>
                                        <div className="mb-3">
                                            <Form.Label>Personal Statement</Form.Label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                            />
                                        </div> */}
                        {/* </Col> */}
                        {/* <Col lg={12}>
                                        <div className="mb-3">
                                            <Form.Label>Personal Interest</Form.Label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                            />
                                        </div>
                                    </Col> */}
                        <Col lg={12}>
                            <div className="mb-3">
                                <Form.Label htmlFor="profileImage">Profile Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="profileImage"
                                    name="Profile_Image"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="blogUrlInput">Blog URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="blogUrlInput"
                                    name="Blog_URL"
                                    placeholder="Enter blog URL"
                                    value={validation.values.Blog_URL}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="seoTitleInput">SEO Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="seoTitleInput"
                                    name="Seo_Title"
                                    placeholder="Enter SEO title"
                                    value={validation.values.Seo_Title}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="seoDescriptionInput">SEO Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="seoDescriptionInput"
                                    name="Seo_Description"
                                    placeholder="Enter SEO description"
                                    value={validation.values.Seo_Description}
                                    onChange={validation.handleChange}
                                />
                            </div>























                        </Col>
                        <Col lg={6}>
                            <div>
                                <Form.Label htmlFor="enquireSelectionInput">Selection of Enquire for Originals</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="enquireSelectionInput"
                                    name="Selection_of_Enquire_for_Orginals"
                                    placeholder="Enter selection"
                                    value={validation.values.Selection_of_Enquire_for_Orginals}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="commissionWorkInput">Commission Work for This Artist</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="commissionWorkInput"
                                    name="Commission_work_for_This_Artist"
                                    placeholder="Enter commission work details"
                                    value={validation.values.Commission_work_for_This_Artist}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="desktopThumbnailImageInput">Artist Thumbnail Desktop Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="desktopThumbnailImageInput"
                                    name="Artist_thumbnail_desktop_Image"
                                    placeholder="Enter desktop thumbnail image URL"
                                    value={validation.values.Artist_thumbnail_desktop_Image}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="mobileThumbnailImageInput">Artist Thumbnail Mobile Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="mobileThumbnailImageInput"
                                    name="Artist_thumbnail_Mobile_Image"
                                    placeholder="Enter mobile thumbnail image URL"
                                    value={validation.values.Artist_thumbnail_Mobile_Image}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="photoEssayTitleInput">Photo Essay Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="photoEssayTitleInput"
                                    name="Photo_Essay_Title"
                                    placeholder="Enter photo essay title"
                                    value={validation.values.Photo_Essay_Title}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="photoEssayDescriptionInput">Photo Essay Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="photoEssayDescriptionInput"
                                    name="Photo_Essay_Description"
                                    placeholder="Enter photo essay description"
                                    value={validation.values.Photo_Essay_Description}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayTitle1Input">Essay Title 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayTitle1Input"
                                    name="Essay_Title_1"
                                    placeholder="Enter essay title 1"
                                    value={validation.values.Essay_Title_1}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayImageOneInput">Essay Image One</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="essayImageOneInput"
                                    name="Essay_Image_One"
                                    placeholder="Enter essay image one URL"
                                    value={validation.values.Essay_Image_One}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayOneDescriptionInput">Essay One Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayOneDescriptionInput"
                                    name="Essay_One_Description"
                                    placeholder="Enter essay one description"
                                    value={validation.values.Essay_One_Description}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayTitle2Input">Essay Title 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayTitle2Input"
                                    name="Essay_Title_2"
                                    placeholder="Enter essay title 2"
                                    value={validation.values.Essay_Title_2}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayImageTwoInput">Essay Image Two</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="essayImageTwoInput"
                                    name="Essay_Image_Two"
                                    placeholder="Enter essay image two URL"
                                    value={validation.values.Essay_Image_Two}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayTwoDescriptionInput">Essay Two Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayTwoDescriptionInput"
                                    name="Essay_Two_Description"
                                    placeholder="Enter essay two description"
                                    value={validation.values.Essay_Two_Description}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayTitle3Input">Essay Title 3</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayTitle3Input"
                                    name="Essay_Title_Three"
                                    placeholder="Enter essay title 3"
                                    value={validation.values.Essay_Title_Three}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayImageThreeInput">Essay Image Three</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="essayImageThreeInput"
                                    name="Essay_Image_Three"
                                    placeholder="Enter essay image three URL"
                                    value={validation.values.Essay_Image_Three}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayThreeDescriptionInput">Essay Three Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayThreeDescriptionInput"
                                    name="Essay_Three_Description"
                                    placeholder="Enter essay three description"
                                    value={validation.values.Essay_Three_Description}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayTitle4Input">Essay Title 4</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayTitle4Input"
                                    name="Essay_Title_4"
                                    placeholder="Enter essay title 4"
                                    value={validation.values.Essay_Title_4}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayImageFourInput">Essay Image Four</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="essayImageFourInput"
                                    name="Essay_Image_Four"
                                    placeholder="Enter essay image four URL"
                                    value={validation.values.Essay_Image_Four}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayFourDescriptionInput">Essay Four Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayFourDescriptionInput"
                                    name="Essay_Four_Description"
                                    placeholder="Enter essay four description"
                                    value={validation.values.Essay_Four_Description}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayTitle5Input">Essay Title 5</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayTitle5Input"
                                    name="Essay_Title_5"
                                    placeholder="Enter essay title 5"
                                    value={validation.values.Essay_Title_5}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayImageFiveInput">Essay Image Five</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="essayImageFiveInput"
                                    name="Essay_Image_Five"
                                    placeholder="Enter essay image five URL"
                                    value={validation.values.Essay_Image_Five}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayFiveDescriptionInput">Essay Five Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayFiveDescriptionInput"
                                    name="Essay_Five_Description"
                                    placeholder="Enter essay five description"
                                    value={validation.values.Essay_Five_Description}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essayTitle6Input">Essay Title 6</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essayTitle6Input"
                                    name="Essay_Title_6"
                                    placeholder="Enter essay title 6"
                                    value={validation.values.Essay_Title_6}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>


                            <Col lg={12}>
                                <div>
                                    <Form.Label>Association with art galleries</Form.Label>
                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'left', paddingBottom: '10px' }}>Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {galleries.map((gallery, index) => (
                                                <tr key={index}>
                                                    <td style={{ paddingBottom: '10px', width: '80%' }}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={gallery.name}
                                                            onChange={(event) => handleGalleryChange(index, event)}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </td>
                                                    <td style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                        <Button variant="light" onClick={() => handleAddGallery()}>
                                                            <img src={dropdown} alt="dropdown" height={18} width={18} />
                                                        </Button>
                                                    </td>
                                                    <td style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                        <Button
                                                            variant="light"
                                                            onClick={() => handleRemoveGallery(index)}
                                                        >
                                                            <img src={deleteicon} alt="delete" height={18} width={18} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Button variant="outline-primary" onClick={handleAddGallery}>
                                        + Add gallery
                                    </Button>
                                </div>

                            </Col>
                            <div>
                                <Form.Label htmlFor="essayImageSixInput">Essay Image Six</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="essayImageSixInput"
                                    name="Essay_Image_Six"
                                    placeholder="Enter essay image six URL"
                                    value={validation.values.Essay_Image_Six}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div>
                                <Form.Label htmlFor="essaySixDescriptionInput">Essay Six Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="essaySixDescriptionInput"
                                    name="Essay_Six_Description"
                                    placeholder="Enter essay six description"
                                    value={validation.values.Essay_Six_Description}
                                    onChange={validation.handleChange}
                                />
                            </div>
                        </Col>
                        {/* <Col lg={12}> */}















                        {/* Add other form fields as needed */}
                        <div className="modal-footer">
                            <div className="hstack gap-2 justify-content-end">
                                {/* <Button className="btn-ghost-danger" onClick={() => { tog_AddSellerModals(); }}>Close</Button> */}
                                <Button variant='success' type='submit' id="add-btn">Update Artist</Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={modal_DeleteConfirm} onHide={() => setModalDeleteConfirm(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this seller?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalDeleteConfirm(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => confirmDelete(deleteSellerId)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>



        </React.Fragment>
    );
};

export default ArtistGridView;