import axios from 'axios';
import React from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';

interface CreateProductStep1Props {
    formik: any;
}

const SupportingImageStep4: React.FC<CreateProductStep1Props> = ({ formik }) => {
    document.title = "Categories | Artflute + Admin React Template"

    const { errors, touched, setFieldValue, handleSubmit } = formik;

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files[0]) {
            try {
                let data: any = new FormData();
                data.append('fileType', 'artist_thumb_desktop');
                data.append('filePath', event.target.files[0]);

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data.mediaPaths[0].filePath));
                        setFieldValue(fieldName, response.data.mediaPaths[0].filePath);
                        console.log('imageData', response.data.mediaPaths[0].filePath)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Error on upload image ", error);
            }
        }
    };

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <Container fluid={true}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Seo</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form
                                        autoComplete="off"
                                        className="needs-validation createCategory-form"
                                        id="createCategory-form"
                                        noValidate
                                        onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_1">
                                                    <Form.Label>Supporting Image 1</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_1"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_1")}
                                                        isInvalid={!!errors.supporting_image_1 && touched.supporting_image_1}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_1}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_2">
                                                    <Form.Label>Supporting Image 2</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_2"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_2")}
                                                        isInvalid={!!errors.supporting_image_2 && touched.supporting_image_2}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_2}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_3">
                                                    <Form.Label>Supporting Image 3</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_3"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_3")}
                                                        isInvalid={!!errors.supporting_image_3 && touched.supporting_image_3}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_3}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_4">
                                                    <Form.Label>Supporting Image 4</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_4"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_4")}
                                                        isInvalid={!!errors.supporting_image_4 && touched.supporting_image_4}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_4}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_5">
                                                    <Form.Label>Supporting Image 5</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_5"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_5")}
                                                        isInvalid={!!errors.supporting_image_5 && touched.supporting_image_5}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_5}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_6">
                                                    <Form.Label>Supporting Image 6</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_6"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_6")}
                                                        isInvalid={!!errors.supporting_image_6 && touched.supporting_image_6}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_6}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_7">
                                                    <Form.Label>Supporting Image 7</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_7"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_7")}
                                                        isInvalid={!!errors.supporting_image_7 && touched.supporting_image_7}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_7}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_8">
                                                    <Form.Label>Supporting Image 8</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_8"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_8")}
                                                        isInvalid={!!errors.supporting_image_8 && touched.supporting_image_8}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_8}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_9">
                                                    <Form.Label>Supporting Image 9</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_9"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_9")}
                                                        isInvalid={!!errors.supporting_image_9 && touched.supporting_image_9}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_9}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_10">
                                                    <Form.Label>Supporting Image 10</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_10"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_10")}
                                                        isInvalid={!!errors.supporting_image_10 && touched.supporting_image_10}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_10}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className='mt-3' controlId="supporting_image_11">
                                                    <Form.Label>Supporting Image 11</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        name="supporting_image_11"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "supporting_image_11")}
                                                        isInvalid={!!errors.supporting_image_11 && touched.supporting_image_11}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.supporting_image_11}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default SupportingImageStep4;
