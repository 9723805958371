import React, { MouseEvent, useMemo, useState, useEffect } from 'react';
import { Button, Card, Col, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import TableContainer from "Common/TableContainer";
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';

const Artist = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataParam = queryParams.get('yy');
  console.log(dataParam, "yyyyyyyyyyyyyyy");
  const allpartners = location?.state?.yy;
  const [data, setData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (dataParam) {
      try {
        const parsedData = JSON.parse(decodeURIComponent(dataParam));
        if (Array.isArray(parsedData)) {
          setData(parsedData);
        } else if (typeof parsedData === 'object' && parsedData !== null) {
          setData([parsedData]);
        } else {
          console.error('Parsed data is not valid');
        }
      } catch (e) {
        console.error('Failed to parse data:', e);
      }
    }
  }, [dataParam]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddAccountClick = () => {
    setShowModal(true);
  };

  const handleSaveClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log('Form data saved.');
    // Optionally, reset the form or take other actions here
    setShowModal(false); // Close the modal after saving
  };

  const handleCancelClick = () => {
    // Logic to handle form cancellation
    console.log('Form cancelled.');
    setShowModal(false); // Hide the form or reset fields
  };

  const columns = useMemo(
    () => [
      {
        Header: "first_name",
        accessor: "first_name",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Rating",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => (
          <React.Fragment>
            {cellProps.rating} <i className="bi bi-star-half ms-1 text-warning fs-12"></i>
          </React.Fragment>
        ),
      },
      {
        Header: "Status",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          switch (cellProps.status) {
            case "Paid":
              return (<span className="badge bg-success-subtle text-success"> {cellProps.status}</span>);
            case "Unpaid":
              return (<span className="badge bg-danger-subtle text-danger"> {cellProps.status}</span>);
            case "Pending":
              return (<span className="badge bg-warning-subtle text-warning"> {cellProps.status}</span>);
            default:
              return (<span className="badge bg-success-subtle text-success"> {cellProps.status}</span>);
          }
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <h1></h1>

      <Col xxl={12}>
        {allpartners?.map((item: any, index: number) => (
          <Card className="mb-4" key={index}>
            <Card.Body>
              <Row className="align-items-center text-center">
                <Col xs={12}>
                  <h4 className="mb-1">{item.first_name}</h4>
                  <p className="mb-0" style={{ color: 'lightblue' }}>
                    <i className="bi bi-envelope"></i> {item.email}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}

        <Card>
          <TableContainer
            columns={columns}
            data={allpartners}
            isGlobalFilter={false}
            iscustomPageSize={false}
            isBordered={false}
            customPageSize={6}
            className="custom-header-css table-card"
            tableClass="table-centered align-middle table-nowrap mb-0"
            theadClass="text-muted table-light"
            SearchPlaceholder='Search Products...'
          />
        </Card>

        <Tabs defaultActiveKey="artworks" id="artist-tabs">
          <Tab eventKey="artworks" title="Artworks">
            <table className="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Title</th>
                  <th>Availability</th>
                  <th>Status</th>
                  <th>Original Price</th>
                  <th>Dimension</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>13943</td>
                  <td>
                    Ryomen sukuna <br /><br />
                    <img
                      src="https://s3.ap-southeast-1.amazonaws.com/art-flute-media/artworks/artwork-/thumbnail/compressed/10_06_24_06_36_04_IMG_20230526_183457_597.jpg"
                      alt="Artwork"
                      style={{ width: '120px', height: '120px' }}
                    />
                  </td>
                  <td>
                    <i className="fa fa-check" style={{ color: 'green' }}></i> Original Sale <br />
                    <i className="fa fa-close" style={{ color: 'red' }}></i> Print Sale <br />
                  </td>
                  <td>Not approved</td>
                  <td>300</td>
                  <td>8.27 X 11.6</td>
                  <td>
                    <a href="https://admin.artflute.com/admin/artwork/13943" className="btn btn-sm btn-primary">
                      View
                    </a>
                    <a href="https://admin.artflute.com/admin/artwork/13943/edit" className="btn btn-sm btn-secondary">
                      Edit
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </Tab>

          <Tab eventKey="artthumbnail" title="Artwork Thumbnail">
            <div>
              <h4>Artwork Thumbnail</h4>
              <Form>
                <Form.Group controlId="image12">
                  <Form.Label>Upload Artwork Thumbnail</Form.Label>
                  <Form.Control type="file" name="image12" />
                </Form.Group>
              </Form>
            </div>
          </Tab>

          <Tab eventKey="bankaccounts" title="Bank Accounts">
            <table className="table">

              <Button

                variant="primary"
                onClick={handleAddAccountClick}
              >
                Add Bank Account
              </Button>
              <tbody><tr>
                <th>Id</th>
                <th>Bank Name</th>
                <th>Account name</th>
                <th>account no.</th>
                <th>IFSC</th>
                <th>Is default?</th>
                <th>Actions</th>
              </tr>

              </tbody></table>


            {/* Modal for adding a new bank account */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add Bank Account</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formAccountNumber">
                    <Form.Label>
                      Account Number <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Account Number"
                    />
                  </Form.Group>

                  <Form.Group controlId="formBankName">
                    <Form.Label>
                      Bank Name <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Bank Name"
                    />
                  </Form.Group>

                  <Form.Group controlId="formBranchName">
                    <Form.Label>
                      Branch Name <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Branch Name"
                    />
                  </Form.Group>


                  <Form.Group controlId="IFSC Code">
                    <Form.Label>
                      IFSC Code <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=" Enter IFSC Code"
                    />
                  </Form.Group>
                </Form>


                <Form.Group controlId="formDefaultAccount">
                  <Form.Check
                    type="checkbox"
                    id="defaultAccount"
                    label="Default Account ?"
                  />
                </Form.Group>




              </Modal.Body>
              <Modal.Footer>

                <Button variant="primary" onClick={handleSaveClick}>
                  Save
                </Button>
                <Button variant="secondary" onClick={handleCancelClick}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </Tab>



          <Tab eventKey="transactions" title="Transactions">
            <table className="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Title</th>
                  <th>Availability</th>
                  <th>Status</th>
                  <th>Original Price</th>
                  <th>Dimension</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {/* Add your transactions data here */}
              </tbody>
            </table>
          </Tab>

          <Tab eventKey="activitylog" title="Activity Log">
            <div>
              <h4>Activity Log</h4>
              <p>
                <a href="/admin/userCustomer/8404">Ariba</a> updated an artist <a href="/admin/artist/1932">Ariba</a> 2 months ago
                <a className="show-more" href="/admin/audit/feed/4e06de7e-a3c4-4e36-9718-4eda5fae69a7">show more</a>
              </p>
              <p>
                <a className="show-more" href="/admin/audit/feed/7b1e6494-a80b-4756-b5b9-8e8115e5ec7a">show more</a>
              </p>
            </div>
          </Tab>

          <Tab eventKey="settings" title="Settings">
            <form
              method="POST"
              action="https://admin.artflute.com/admin/artist/user/update/8404"
              acceptCharset="UTF-8"
              className="form-horizontal"
              id="ArtistUserEditForm"
              encType="multipart/form-data"
              data-parsley-validate=""
              noValidate
            >
              <input name="_method" type="hidden" value="PUT" />
              <input name="_token" type="hidden" value="MoxE6xd9GT6R0naFoqP5JWAFjgNrrUfl6iPhZlJj" />

              <div className="form-group">
                <label htmlFor="inputName" className="col-sm-2 control-label">First Name</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    required
                    name="first_name"
                    value="Ariba"
                    className="form-control"
                    id="inputName"
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="inputName" className="col-sm-2 control-label">Last Name</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="last_name"
                    value="Ayub"
                    className="form-control"
                    id="inputName"
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="inputStatus" className="col-sm-2 control-label">Status</label>
                <div className="col-sm-10">
                  <select name="status" className="form-control">
                    <option selected value="0">Inactive</option>
                    <option value="1">Active</option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="inputEmail" className="col-sm-2 control-label">Email</label>
                <div className="col-sm-10">
                  <input
                    disabled
                    type="email"
                    value="aribaayub9120@gmail.com"
                    className="form-control"
                    id="inputEmail"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="inputPassword" className="col-sm-2 control-label">Password</label>
                <div className="col-sm-10">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    id="inputPassword"
                    placeholder="Password"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="inputPhone" className="col-sm-2 control-label">Phone</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="phone"
                    value=""
                    className="form-control"
                    id="inputPhone"
                    placeholder="Phone"
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="inputImage" className="col-sm-2 control-label">Upload Image</label>
                <div className="col-sm-10">
                  <input
                    type="file"
                    name="image"
                    id="inputImage"
                  />
                </div>
              </div>

              <Button type="submit" className="btn btn-primary">Update</Button>
            </form>
          </Tab>
        </Tabs>
      </Col>
    </React.Fragment>
  );
};

export default Artist;


