import React, { useState, useMemo, useEffect} from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import TableContainer from "Common/TableContainer";
import { ListView } from "Common/data";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { useDebounce } from 'use-debounce';

const ArtWidgetsListTable = () => {
    const [modal_AddUserModals, setmodal_AddUserModals] = useState<boolean>(false);
    const [Widgetslist, setWidgetsList] = useState<any>([]);
    const [modal_DeleteConfirm, setModalDeleteConfirm] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500); 
    const [noData, setNoData] = useState<boolean>(false); 


    const [page, setPage] = useState(0); 
    
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [limit, setLimit] = useState<number>(1);

    const perPageData = 10;

    const fetchWidgetsList = async (page: number, limit: number) => {
        setLoader(true);
        try {

            const tokenString = localStorage.getItem('authUser');
                let token = '';
    
                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString);
                    token = tokenObject.token;
                }
            const queryParams = new URLSearchParams({
                search: debouncedSearchTerm, 
                page: page.toString(),
                limit: limit.toString(),
            }).toString();

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/get_art_widges?${queryParams}`, 
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            console.log(response.data, "mmmmmmmmmmmmmmmmmmmmmmm" )
    //         setWidgetsList(response.data);
    //     } catch (error) {
    //         console.error("Error fetching seller list:", error);
    //     } finally {
    //         setLoader(false);
    //     }
    // };

    if (response?.data?.length === 0) {
        setWidgetsList([]); // Clear data
        setNoData(true);
    } else {
        setWidgetsList(response.data);
        setNoData(false);
    }
} catch (error) {
    console.error('API Error:', error);
    setWidgetsList([]); // Clear data on error
    setNoData(true);
} finally {
    setLoader(false);
}
};
    useEffect(() => {
        fetchWidgetsList(currentPage, limit);
    }, [debouncedSearchTerm, currentPage, limit]); 
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

console.log(Widgetslist, "cccccccccccccccc")
console.log(JSON.stringify(Widgetslist, null, 2), "Response data");


    const handleDeleteClick = (item: any) => {
        setSelectedItem(item);
        setModalDeleteConfirm(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            console.log('Deleting item:', selectedItem);
            
            setModalDeleteConfirm(false);
            setLoader(true);
    
            try {
                const tokenString = localStorage.getItem('authUser');
                let token = '';
    
                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
                    token = tokenObject.token;
                }
    
                await axios.get(
                    `${process.env.REACT_APP_API_BASEURL}/auth/delete_art_widges`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        params: {
                            id: selectedItem.id,
                        },
                    }
                );
    
                
           
                fetchWidgetsList(page, limit);
    
            } catch (error) {
                console.error('Error deleting item:', error);
            } finally {
                setLoader(false);
                setModalDeleteConfirm(false);
            }
        }
    };
    
    


    const handleEditClick = (item: any) => {
        console.log("Editing item:", item); // Add this line to inspect the item
        setSelectedItem(item);
        formikEdit.setValues({
            id: item.id || '',
            key: item.key || '',
        });
        setShowEditModal(true);
    };
    

    const handleAddClick = () => {
        formikAdd.resetForm();
        setShowAddModal(true);
    };

    const handleClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setModalDeleteConfirm(false);

    };
    
    

    const formikAdd = useFormik({
        initialValues: {
            key: '',
           
        },
        validationSchema: Yup.object({
            // key: Yup.string().required('key is required'),
         
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('Adding new item:', values);
            setLoader(true);
            try {
                const tokenString = localStorage.getItem('authUser');
                let token = '';
    
                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString);
                    token = tokenObject.token;
                }
    
             const yy=   await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/add_art_widges`,
                    values,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
    console.log(yy, "yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy" )
                resetForm();
                handleClose();
                fetchWidgetsList(page, limit);
            } catch (error) {
                console.error('Error adding item:', error);
            } finally {
                setLoader(false);
            }
        },
    });
    

    

    const formikEdit = useFormik({
        enableReinitialize: true,
        initialValues: {
            id:'',
            key: '',
          
        },
        validationSchema: Yup.object({
            // Label: Yup.string().required('Label is required'),
            // Value: Yup.string().required('Value is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('Updating item:', values);
            setLoader(true);
            try {
                const tokenString = localStorage.getItem('authUser');
                let token = '';
    
                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString);
                    token = tokenObject.token;
                }
    
                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/edit_art_widges`,
                    values,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
    
                resetForm();
                handleClose();
                fetchWidgetsList(page, limit);
            } catch (error) {
                console.error('Error adding item:', error);
            } finally {
                setLoader(false);
            }
        },
    });
     


   

const ListView = Widgetslist.map((item: { id: any; key: any;}) => ({
    id: item.id,
    key: item.key,
  
}));
    const columns = useMemo(
        () => [
            {
                Header: "Key",
                accessor: (cellProps: any) => {
                    console.log('cellPropsssss:', cellProps);  
                    return (
                        <div className="d-flex align-items-center gap-2">
                            <div className="flex-grow-1 ms-2 user_name">{cellProps.key}</div>
                        </div>
                    );
                },
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Action",
                accessor: (cellProps: any) => (
                    <div className="d-flex gap-2">
                        <Button
                            variant="soft-danger"
                            size="sm"
                            onClick={() => handleDeleteClick(cellProps)}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="soft-info"
                            size="sm"
                            onClick={() => handleEditClick(cellProps)}
                        >
                            Edit
                        </Button>
                    </div>
                ),
                disableFilters: true,
                filterable: true,
            },
        ],
        []
    );



    

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card id="invoiceList">
                        <Card.Header className="border-0">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <div className="d-flex gap-2 flex-wrap">
                                        {isMultiDeleteButton && (
                                            <Button variant="danger" className="btn-icon">
                                                <i className="ri-delete-bin-2-line"></i>
                                            </Button>
                                        )}
                                        <Button variant="primary" onClick={handleAddClick}>
                                            <i className="ri-add-line align-bottom me-1"></i> Add Art Widgets
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>

                        <Card.Body className="bg-soft-light border border-dashed border-start-0 border-end-0">
                            <Row className="g-3">
                                <Col xxl={5} sm={12}>
                                    <div className="search-box">
                                        <input
                                            type="text"
                                            className="form-control search bg-light border-light"
                                            placeholder="Search..." 
                                            value={searchTerm}
                                            onChange={handleSearchChange} 
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>

                        <Card.Body className="p-0">
    <TableContainer
        columns={columns}
        data={ListView}
        sellerListss={ListView}
        iscustomPageSize={false}
        isBordered={false}
        customPageSize={perPageData}
        className="custom-header-css"
        tableClass="table-centered align-middle table-nowrap mb-0"
        theadClass="text-muted"
        SearchPlaceholder="Search Products..."
    />
    {noData && (
        <div className="text-center py-4">
            <div className="avatar-md mx-auto mb-4">
                <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                    <i className="bi bi-search"></i>
                </div>
            </div>
            <h5 className="mt-2">Sorry! No Result Found</h5>
        </div>
    )}
</Card.Body>


                    </Card>
                </Col>
            </Row>

       
            <Modal show={showAddModal} onHide={handleClose}>
                <Modal.Header className="px-4 pt-4" closeButton>
                    <h5 className="modal-title">Add Art Widgets</h5>
                </Modal.Header>
                <Form onSubmit={formikAdd.handleSubmit} className="tablelist-form">
                    <Modal.Body className="p-4">
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="key">key</Form.Label>
                                    <Form.Control
                                        name="key"
                                        id="key"
                                        onChange={formikAdd.handleChange}
                                        onBlur={formikAdd.handleBlur}
                                        value={formikAdd.values.key}
                                        isInvalid={formikAdd.touched.key && !!formikAdd.errors.key}
                                    />
                                    {formikAdd.touched.key && formikAdd.errors.key ? (
                                        <Form.Control.Feedback type="invalid">
                                            {formikAdd.errors.key}
                                        </Form.Control.Feedback>
                                    ) : null}
                                </Form.Group>
                            </Col>
                           
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit">
                            Add
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showEditModal} onHide={handleClose}>
                <Modal.Header className="px-4 pt-4" closeButton>
                    <h5 className="modal-title">Edit Art Widgets</h5>
                </Modal.Header>
                <Form onSubmit={formikEdit.handleSubmit} className="tablelist-form">
                    <Modal.Body className="p-4">
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="key">key</Form.Label>
                                    <Form.Control
                                        name="key"
                                        id="key"
                                        onChange={formikEdit.handleChange}
                                        onBlur={formikEdit.handleBlur}
                                        value={formikEdit.values.key}
                                        isInvalid={formikEdit.touched.key && !!formikEdit.errors.key}
                                    />
                                    {formikEdit.touched.key && formikEdit.errors.key ? (
                                        <Form.Control.Feedback type="invalid">
                                            {formikEdit.errors.key}
                                        </Form.Control.Feedback>
                                    ) : null}
                                </Form.Group>
                            </Col>
                           
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit">
                            Save Changes
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={modal_DeleteConfirm} onHide={handleClose}>
                <Modal.Header className="px-4 pt-4" closeButton>
                    <h5 className="modal-title">Delete Art Widgets</h5>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <p>Are you sure you want to delete this item?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default ArtWidgetsListTable