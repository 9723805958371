import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import CountUp from 'react-countup';
import Mediums from './ArtMediumsListTable';

const ArtMediumsListTable = () => {


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>

                   
                    <Mediums />
                    

                </Container>
            </div>
        </React.Fragment>
    );
};

export default ArtMediumsListTable;