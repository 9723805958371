import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

interface FormValues {
    formik: any;
}

const SocialMedia: React.FC<FormValues> = ({ formik }) => {
    const { values, errors, touched, handleChange } = formik;


    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    <h5 className="fs-16">Social Media</h5>
                    <p className="text-muted mb-lg-0">Personal Identifiable Information (PII) is defined as: Any representation of information that permits the identity of an individual to whom the information applies to be reasonably inferred by either direct or indirect means.</p>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Row className="g-3">
                                <Col lg={4}>
                                    <Form.Group controlId="facebook_profile_url">
                                        <Form.Label>facebook </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="facebook_profile_url"
                                            placeholder="Enter facebook url"
                                            value={values.facebook_profile_url}
                                            onChange={handleChange}
                                            isInvalid={!!errors.facebook_profile_url && touched.facebook_profile_url}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.facebook_profile_url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="twitter">
                                        <Form.Label>Twitter  </Form.Label>
                                        <Form.Control
                                            type="test"
                                            name="twitter"
                                            placeholder="Enter twitter url"
                                            value={values.twitter}
                                            onChange={handleChange}
                                            isInvalid={!!errors.twitter && touched.twitter}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.twitter}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="linkedIn_profile_url">
                                        <Form.Label>LinkedIn  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="linkedIn_profile_url"
                                            placeholder="Enter linkedIn url"
                                            value={values.linkedIn_profile_url}
                                            onChange={handleChange}
                                            isInvalid={!!errors.linkedIn_profile_url && touched.linkedIn_profile_url}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.linkedIn_profile_url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="website_url">
                                        <Form.Label>Website Url  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="website_url"
                                            placeholder="Enter Website url"
                                            value={values.website_url}
                                            onChange={handleChange}
                                            isInvalid={!!errors.website_url && touched.website_url}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.website_url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="blog_url">
                                        <Form.Label>Blog Url  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="blog_url"
                                            placeholder="Enter blog url"
                                            value={values.blog_url}
                                            onChange={handleChange}
                                            isInvalid={!!errors.blog_url && touched.blog_url}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.blog_url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group controlId="seo_url">
                                        <Form.Label>Seo Url  </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="seo_url"
                                            placeholder="Enter seo url "
                                            value={values.seo_url}
                                            onChange={handleChange}
                                            isInvalid={!!errors.seo_url && touched.seo_url}
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-danger'>
                                            {errors.seo_url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SocialMedia;