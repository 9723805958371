import React, { useState, useMemo, useEffect } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import TableContainer from "Common/TableContainer";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useDebounce } from 'use-debounce';

const Addartstyle = () => {
    const [modal_AddUserModals, setmodal_AddUserModals] = useState<boolean>(false);
    const [Subjectslist, setSubjectsList] = useState<any[]>([]);
    const [modal_DeleteConfirm, setModalDeleteConfirm] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const [noDataFound, setNoDataFound] = useState<boolean>(false);

    const [page, setPage] = useState(0); 

    const [noData, setNoData] = useState<boolean>(false); 
   
    const [totalCount, setTotalCount] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [limit, setLimit] = useState<number>(1);

    const perPageData = 10;
    const fetchSubjectsList = async (page: number, limit: number) => {
        setLoader(true);
        setNoDataFound(false);

        try {
            const tokenString = localStorage.getItem('authUser');
            let token = '';

            if (tokenString) {
                const tokenObject = JSON.parse(tokenString);
                token = tokenObject.token;
            }

    //         const queryParams = new URLSearchParams({
    //             search: debouncedSearchTerm,
    //             page: page.toString(),
    //             limit: limit.toString()
                
    //         }).toString();

    //         const response = await axios.get(
    //             `${process.env.REACT_APP_API_BASEURL}/auth/get_art_style?${queryParams}`,
    //             {
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`,
    //                 }
    //             }
    //         );

    //         if (response.data && response.data.length === 0) {
    //             setNoDataFound(true);
    //             setSubjectsList([]);
    //         } else if (response.data) {
    //             setSubjectsList(response.data);
    //             setNoDataFound(false);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching seller list:", error);
    //         setNoDataFound(true);
    //         setSubjectsList([]);
    //     } finally {
    //         setLoader(false);
    //     }
    // };

    // useEffect(() => {
    //     fetchSubjectsList(page, limit);
    // }, [debouncedSearchTerm, page, limit]);

    const queryParams = new URLSearchParams({
        search: debouncedSearchTerm,
        page: page.toString(),
        limit: limit.toString(),
    }).toString();

    const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/auth/get_art_style?${queryParams}`,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
    );

    if (response.data && response.data.length === 0) {
        setNoDataFound(true);
        setSubjectsList([]);
    } else if (response.data) {
        setSubjectsList(response.data);
        setTotalPages(response.data.length || 1);  // Update the total pages from API response
        setNoDataFound(false);
    }
} catch (error) {
    console.error("Error fetching art styles list:", error);
    setNoDataFound(true);
    setSubjectsList([]);
} finally {
    setLoader(false);
}
};

useEffect(() => {
fetchSubjectsList(currentPage, limit);
}, [debouncedSearchTerm, currentPage, limit]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleDeleteClick = (item: any) => {
        setSelectedItem(item);
        setModalDeleteConfirm(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedItem) {
            console.log('Deleting item:', selectedItem);

            setModalDeleteConfirm(false);
            setLoader(true);

            try {
                const tokenString = localStorage.getItem('authUser');
                let token = '';

                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
                    token = tokenObject.token;
                }

                await axios.get(
                    `${process.env.REACT_APP_API_BASEURL}/auth/delete_art_style`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        params: {
                            id: selectedItem.id,
                        },
                    }
                );

               
                fetchSubjectsList(page, limit);
            } catch (error) {
                console.error('Error deleting item:', error);
            } finally {
                setLoader(false);
                setModalDeleteConfirm(false);
            }
        }
    };

    const handleEditClick = (item: any) => {
        setSelectedItem(item);
        formikEdit.setValues({
            id: item.id || '',
            Label: item.Label || '',
            Value: item.Value || '',
        });
        setShowEditModal(true);
    };

    const handleAddClick = () => {
        formikAdd.resetForm();
        setShowAddModal(true);
    };

    const handleClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setModalDeleteConfirm(false);
    };

    const formikAdd = useFormik({
        initialValues: {
            Label: '',
            Value: '',
        },
        validationSchema: Yup.object({}),
        onSubmit: async (values, { resetForm }) => {
            console.log('Adding new item:', values);
            setLoader(true);
            try {
                const tokenString = localStorage.getItem('authUser');
                let token = '';

                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString);
                    token = tokenObject.token;
                }

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/add_art_style`,
                    values,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );

                resetForm();
                handleClose();
            
                fetchSubjectsList(page, limit);
            } catch (error) {
                console.error('Error adding item:', error);
            } finally {
                setLoader(false);
            }
        },
    });

    const formikEdit = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: '',
            Label: '',
            Value: '',
        },
        validationSchema: Yup.object({}),
        onSubmit: async (values, { resetForm }) => {
            console.log('Updating item:', values);
            setLoader(true);
            try {
                const tokenString = localStorage.getItem('authUser');
                let token = '';

                if (tokenString) {
                    const tokenObject = JSON.parse(tokenString);
                    token = tokenObject.token;
                }

                await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/edit_art_style`,
                    values,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );

                resetForm();
                handleClose();
             
                fetchSubjectsList(page, limit);
                
            } catch (error) {
                console.error('Error editing item:', error);
            } finally {
                setLoader(false);
            }
        },
    });

    const ListView = Subjectslist.map((item: { id: any; Label: any; Value: any; }) => ({
        id: item.id,
        Label: item.Label,
        Value: item.Value,
    }));

    const columns = useMemo(
        () => [
            {
                Header: "Label",
                accessor: (cellProps: any) => (
                    <span className="fw-medium">{cellProps.Label}</span>
                ),
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Value",
                accessor: (cellProps: any) => (
                    <div className="d-flex align-items-center gap-2">
                        <div className="flex-grow-1 ms-2 user_name">{cellProps.Value}</div>
                    </div>
                ),
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Action",
                accessor: (cellProps: any) => (
                    <div className="d-flex gap-2">
                        <Button
                            variant="soft-danger"
                            size="sm"
                            onClick={() => handleDeleteClick(cellProps)}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="soft-info"
                            size="sm"
                            onClick={() => handleEditClick(cellProps)}
                        >
                            Edit
                        </Button>
                    </div>
                ),
                disableFilters: true,
                filterable: true,
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card id="invoiceList">
                        <Card.Header className="border-0">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <div className="d-flex gap-2 flex-wrap">
                                        {isMultiDeleteButton && (
                                            <Button variant="danger" className="btn-icon">
                                                <i className="ri-delete-bin-2-line"></i>
                                            </Button>
                                        )}
                                        <Button variant="primary" onClick={handleAddClick}>
                                            <i className="ri-add-line align-bottom me-1"></i> Add Art style
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>

                        <Card.Body className="bg-soft-light border border-dashed border-start-0 border-end-0">
                            <Row className="g-3">
                                <Col sm={12} lg={4}>
                                    <Form.Control
                                        type="text"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        placeholder="Search..."
                                    />
                                </Col>
                            </Row>
                        </Card.Body>

                        <Card.Body className="pt-0">
                       
                                <TableContainer
                                 
                                sellerListss={ListView}
                                    columns={columns}
                                    
                                    data={ListView}
                                     page={currentPage}
                                    limit={perPageData}
                                    iscustomPageSize={false}
                                  
                               
                                 
                isBordered={false}
                customPageSize={perPageData}
                className="custom-header-css table align-middle table-nowrap"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Search Products...'
                                />

{noDataFound && (
        <div className="text-center py-4">
            <div className="avatar-md mx-auto mb-4">
                <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                    <i className="bi bi-search"></i>
                </div>
            </div>
            <h5 className="mt-2">Sorry! No Result Found</h5>
        </div>
    )}
                      
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Add Art Style Modal */}
            <Modal show={showAddModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Art Style</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formikAdd.handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Label</Form.Label>
                            <Form.Control
                                type="text"
                                {...formikAdd.getFieldProps('Label')}
                                isInvalid={!!formikAdd.errors.Label}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formikAdd.errors.Label}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Value</Form.Label>
                            <Form.Control
                                type="text"
                                {...formikAdd.getFieldProps('Value')}
                                isInvalid={!!formikAdd.errors.Value}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formikAdd.errors.Value}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button variant="primary" type="submit" className="me-2">
                                Add

                            </Button>
                            <Button  variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                           
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Edit Art Style Modal */}
            <Modal show={showEditModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Art Style</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formikEdit.handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Label</Form.Label>
                            <Form.Control
                                type="text"
                                {...formikEdit.getFieldProps('Label')}
                                isInvalid={!!formikEdit.errors.Label}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formikEdit.errors.Label}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Value</Form.Label>
                            <Form.Control
                                type="text"
                                {...formikEdit.getFieldProps('Value')}
                                isInvalid={!!formikEdit.errors.Value}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formikEdit.errors.Value}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit" className="ms-2">
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={modal_DeleteConfirm} onHide={() => setModalDeleteConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalDeleteConfirm(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default Addartstyle;