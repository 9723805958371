import React, { useState } from 'react';
import Select from 'react-select';

const sizes = [
    { value: '30x40', label: '30x40' },
    { value: '40x50', label: '40x50' },
    { value: '50x60', label: '50x60' },
    { value: '60x70', label: '60x70' },
    { value: '70x80', label: '70x80' },
    { value: '80x90', label: '80x90' },
];

const ProductSize = () => {
    const [selectedSizes, setSelectedSizes] = useState<{ value: string; label: string }[]>([]);

    const handleSizeChange = (selectedOptions: any) => {
        setSelectedSizes(selectedOptions || []);
    };

    return (
        <div className='p-3' style={{ borderBottom: "1px solid #e9ebec" }}>
            <p className="text-muted text-uppercase fs-13 mb-3">Sizes</p>
            <Select
                isMulti
                options={sizes}
                value={selectedSizes}
                onChange={handleSizeChange}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select sizes..."
            />
        </div>
    );
};

export default ProductSize;
