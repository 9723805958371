
import React, { useState, useEffect } from 'react';
import Select, { MultiValue } from 'react-select';
import { Container, Row, Col, Card, Dropdown, CardHeader, CardFooter } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import ProductCategoryFilter from '../ListView/ProductCategoryFilter';
import axios from 'axios';
import noImage from '../../../assets/images/no-image.png'
import { useNavigate } from 'react-router-dom';

const options = [
    { value: 'Original Sale', label: 'Original Sale' },
    { value: 'Print Sale', label: 'Print Sale' },
    { value: 'Desktop Art', label: 'Desktop Art' }
];

interface OptionType {
    value: string;
    label: string;
  }
  
  const optionss: OptionType[] = [
    { value: 'Original Sale', label: 'Original Sale' },
    { value: 'Print Sale', label: 'Print Sale' },
    { value: 'Desktop Art', label: 'Desktop Art' }
  ];

const catOptions = [
    { value: 'Original Sale', label: 'Original Sale' },
    { value: 'Print Sale', label: 'Print Sale' },
    { value: 'Desktop Art', label: 'Desktop Art' }
];


const formatPublishedDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const getDayWithSuffix = (day: number) => {
        if (day > 3 && day < 21) return day + 'th';
        switch (day % 10) {
            case 1: return day + 'st';
            case 2: return day + 'nd';
            case 3: return day + 'rd';
            default: return day + 'th';
        }
    };

    return `${getDayWithSuffix(day)} ${month}, ${year}`;
};


const GridView: React.FC = () => {
    document.title = "Product Grid | Artflute + Admin React Template";
    const navigate = useNavigate();
    const [pagination, setPagination] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [sellerList, setSellerList] = useState<any[]>([]);
    const [artistList, setArtistList] = useState<OptionType[]>([]);
    const [loader, setLoader] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState<string | number>('');
    const [artistFilter, setArtistFilter] = useState<MultiValue<OptionType>>([]);
    const [availabilityFilter, setAvailabilityFilter] = useState<MultiValue<OptionType>>([]);
    const [catFilter, setCatFilter] = useState<any[]>([]);
const perPageData = 10;

const fetchSellerList = async (page: number) => {
    setLoader(true);
    try {
        const tokenString = localStorage.getItem('authUser');
        let token = '';

        if (tokenString) {
            const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
            token = tokenObject.token;
        }

        const availability = {
            Is_available_for_original_sale: availabilityFilter.some(option => option.value === 'Original Sale') ? 'yes' : '',
            Is_available_for_print_sale: availabilityFilter.some(option => option.value === 'Print Sale') ? 'yes' : '',
            Is_vailable_for_desktop_art: availabilityFilter.some(option => option.value === 'Desktop Art') ? 'yes' : ''
        };

        const response = await axios.post(
            `${process.env.REACT_APP_API_BASEURL}/auth/get_all_artworks`,
            {
                status: statusFilter,
                availability,
                artist_name: artistFilter.map(option => option.label),
                page: page,
                limit: perPageData
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (Array.isArray(response.data)) {
            setSellerList(response.data);
        } else {
            console.error("API response is not an array:", response.data);
            setSellerList([]);
        }
    } catch (error) {
        console.error("Error fetching seller list:", error);
        setSellerList([]);
    } finally {
        setLoader(false);
    }
};

  const fetchArtistList = async () => {
    setLoader(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/auth/get_all_artist_name`);

      if (Array.isArray(response.data)) {
        const formattedOptions: OptionType[] = response.data.map((artist: any) => ({
          value: artist.id.toString(), // Ensure value is a string
          label: artist.name
        }));
        setArtistList(formattedOptions);
      } else {
        console.error("API response is not an array:", response.data);
        setArtistList([]);
      }
    } catch (error) {
      console.error("Error fetching artist list:", error);
      setArtistList([]);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchSellerList(currentPage);
  }, [statusFilter, availabilityFilter, artistFilter, currentPage]);

  useEffect(() => {
    fetchArtistList();
  }, []);

  const handleArtistFilterChange = (selectedOptions: MultiValue<OptionType>) => {
    setArtistFilter(selectedOptions || []);
  };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setStatusFilter(value === '' ? '' : parseInt(value, 10));
    };

   

    const handleViewDetailsClick = (id: any) => {
        console.log('Item ID:', id);
        navigate(`/artworks/arworkdetails`, { state: { data: id } }); 
    };

    
    
      const handleAvailabilityFilterChange = (selectedOptions: MultiValue<OptionType>) => {
        setAvailabilityFilter(selectedOptions || []);
      };
    

    const handleCatFilterChange = (selectedOptions: any) => {
        setCatFilter(selectedOptions || []);
    };

    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;

    const filteredSellerList = sellerList.filter(item => {
        const matchesSearchTerm = item.Title?.toLowerCase().includes(searchTerm.toLowerCase()) || !searchTerm;
        const matchesStatus = statusFilter !== ''
            ? item.Status === statusFilter
            : true;

        const matchesAvailability = availabilityFilter.length > 0 ? availabilityFilter.some(option => {
            switch (option.value) {
                case 'Original Sale':
                    return item.Is_available_for_original_sale?.toLowerCase() === 'yes';
                case 'Print Sale':
                    return item.Is_available_for_print_sale?.toLowerCase() === 'yes';
                case 'Desktop Art':
                    return item.Is_vailable_for_desktop_art?.toLowerCase() === 'yes';
                default:
                    return false;
            }
        }) : true;

        const matchesArtist = artistFilter.length > 0 ? artistFilter.some(option => {
          
            return option.label === item.artist_name;
        }) : true;

        return matchesSearchTerm && matchesStatus && matchesAvailability && matchesArtist;
    }).slice(indexOfFirst, indexOfLast);
console.log(artistList, "artistListartistListartistListartistListartistList")
const pageNumbers = Array.from({ length: Math.ceil(sellerList.length / perPageData) }, (_, i) => i + 1);

const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
};

const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, pageNumbers.length));
};


    return (
        <div className="page-content">
            <Container fluid={true}>
                <Breadcrumb title="Artworks" pageTitle="Artworks" />
                <Row>
                    <Col xl={3} lg={4}>
                        <Card className="">
                            <Card.Header>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0">Filters</h5>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <a href="#" className="text-decoration-underline" id="clearall">Clear All</a>
                                    </div>
                                </div>
                            </Card.Header>
                            {/* <div className="">
                                <label htmlFor="statusFilter" className="text-muted text-uppercase fs-13 mb-0" style={{ padding: "19.2px", paddingBottom: "12px" }}>Status</label>
                                <select
                                    id="statusFilter"
                                    className="form-select"
                                    value={statusFilter}
                                    onChange={handleStatusFilterChange}
                                >
                                    <option value="">All</option>
                                    <option value="0">Waiting for approval</option>
                                    <option value="1">Approved</option>
                                    <option value="2">Sold Out</option>
                                    <option value="3">Disabled</option>
                                    <option value="4">Not approved</option>
                                    <option value="5">Enable</option>
                                    <option value="6">Available For Commission Work</option>


                                </select>
                            </div> */}
                            <ProductCategoryFilter />
                            <Card.Body>

                                <div className="mb-3">
                                    <label htmlFor="availabilityFilter" className="text-muted text-uppercase fs-13 mb-3">Availability</label>
                                    <Select
                                        id="availabilityFilter"
                                        isMulti
                                        options={options}
                                        value={availabilityFilter}
                                        onChange={handleAvailabilityFilterChange}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>


                                <div className="mb-3">
                  <label htmlFor="artistFilter" className="text-muted text-uppercase fs-13 mb-3">Artists</label>
                  <Select
                    id="artistFilter"
                    isMulti
                    options={artistList}
                    value={artistFilter}
                    onChange={handleArtistFilterChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select artists..."
                  />
                </div>
                                
                            </Card.Body>
                            <Card.Body style={{ borderTop: "1px solid #e9ebec" }}>
                                {/* <div className="mb-3">
                                    <label htmlFor="sellerSearch" className="text-muted text-uppercase fs-13 mb-3">Search Seller</label>
                                    <input
                                        type="text"
                                        id="sellerSearch"
                                        className="form-control"
                                        placeholder="Search Seller..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                </div> */}
                            </Card.Body>
                            <div className="p-3" style={{ borderTop: "1px solid #e9ebec" }}>
                                <label htmlFor="statusFilter" className="text-muted text-uppercase fs-13 mb-0 mb-3" >Status</label>
                                <select
                                    id="statusFilter"
                                    className="form-select"
                                    value={statusFilter}
                                    onChange={handleStatusFilterChange}
                                >
                                    <option value="">All</option>
                                    <option value="0">Waiting for approval</option>
                                    <option value="1">Approved</option>
                                    <option value="2">Sold Out</option>
                                    <option value="3">Disabled</option>
                                    <option value="4">Not approved</option>
                                    <option value="5">Enable</option>
                                    <option value="6">Available For Commission Work</option>
                                    <option value="7">Deleted</option>


                                </select>
                            </div>
                            <div className="p-3" style={{ borderTop: "1px solid #e9ebec" }}>
                                <p className="text-muted text-uppercase fs-13">Categories</p>
                                <Select
                                    id="availabilityFilter"
                                    isMulti
                                    options={catOptions}
                                    value={catFilter}
                                    onChange={handleCatFilterChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </div>
                        </Card>
                    </Col>

                    <Col xl={9} lg={8}>
                        <Row className="g-4 mb-4">
                            <Col className="col-sm-auto">
                                <div>
                                    <a href="/artworks/artworks-v1" className="btn btn-primary" id="addproduct-btn">
                                        <i className="ri-add-line align-bottom me-1"></i>Add Artwork
                                    </a>
                                </div>
                            </Col>
                            <Col className="col-sm">
                                <div className="d-flex justify-content-sm-end">
                                    <div className="search-box ms-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            id="searchProductList"
                                            placeholder="Search Artworks..."
                                            onChange={handleSearchChange}
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row id="product-grid">
                            {filteredSellerList.length > 0 ? (
                                filteredSellerList.map((item: any, key: number) => (
                                    <Col lg={4} sm={6} key={key}>
                                        <Card className="ecommerce-product-widgets">
                                            <CardHeader>
                                                {/* <h6>{item.id}</h6> */}
                                                <Dropdown className="action">
                                                    <Dropdown.Toggle className="btn btn-soft-secondary btn-sm btn-icon arrow-none">
                                                        <i className="ph-dots-three-outline" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as="ul">
                                                        <li> <Dropdown.Item href="#"> Edit </Dropdown.Item> </li>
                                                        <li> <Dropdown.Item href="#"> Remove </Dropdown.Item> </li>
                                                        {/* <li> <Dropdown.Item href="/artworks/arworkdetails"> viewdetails </Dropdown.Item> </li> */}

                                                        <Dropdown.Item
                                                            href="/artworks/arworkdetails"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleViewDetailsClick(item.id);
                                                                // window.location.href = `/artworks/arworkdetails?id=${item.id}`; // Navigate with id as query param
                                                            }}
                                                        >
                                                            View Details
                                                        </Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div>
                                                    {item.Title === null ? <span style={{ color: "transparent" }}>null</span> : <span>{item.Title}</span>}
                                                </div>
                                            </CardHeader>
                                            <Card.Body className='p-0 border-0'>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '246.3px',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <img
                                                        src={item.Artwork_image === null ? noImage : item.Artwork_image}
                                                        alt={item.title}
                                                        style={{
                                                            width: '100%',
                                                            height: "100%",
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ padding: "19.2px" }}>
                                                    {/* <div className="mb-2 d-flex justify-content-between align-items-center">
                                                        <span>{item.category_name}</span>

                                                        <span> {item.Status == '0' ? 'Waiting for approval' :
                                                            item.Status == '1' ? 'Approved' :
                                                                item.Status == '2' ? 'Sold Out' :
                                                                    item.Status == '3' ? 'Disabled' :
                                                                        item.Status == '4' ? 'Not approved' :
                                                                            item.Status == '5' ? 'Enable' :
                                                                                item.Status == '6' ? 'Available For Commission Work' :
                                                                                    item.Status}</span>
                                                    </div> */}


                                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                                        <span>{item.artist_name}</span>
                                                    </div>
                                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                                        <span>Original Sale: {item.Is_available_for_original_sale === 'yes' ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green" className="bi bi-check" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-x" viewBox="0 0 16 16">
                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                            </svg>
                                                        )}</span>
                                                        <span>
                                                            {item.Originals_price === null ? '' : `₹ ${item.Originals_price}`}
                                                        </span>

                                                    </div>
                                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                                        <span>
                                                            Print Sale: {item.Is_available_for_print_sale === 'yes' ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green" className="bi bi-check" viewBox="0 0 16 16">
                                                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-x" viewBox="0 0 16 16">
                                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                        <span>
                                                            {item.Originals_price_dollar === null ? '' : `$ ${item.Originals_price_dollar}`}
                                                        </span>
                                                    </div>

                                                </div>
                                            </Card.Body>
                                            <CardFooter>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>{formatPublishedDate(item.published_at)}</div>

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span>{item.category_name}</span>

                                                        <span style={{
                                                            padding: '5px',
                                                            borderRadius: '4px',
                                                            color: '#fff',
                                                            fontSize: "12px",
                                                            backgroundColor:
                                                                item.Status === 0 ? '#A4DD74' :  // Waiting for approval
                                                                    item.Status === 1 ? '#A4DD74' :  // Approved
                                                                        item.Status === 2 ? '#e85342' :  // Sold Out
                                                                            item.Status === 3 ? '#a1a1a1' :  // Disabled
                                                                                item.Status === 4 ? '#5454E8' :  // Not approved
                                                                                    item.Status === 5 ? '#A4DD74' :  // Enable
                                                                                        item.Status === 6 ? '#A4DD74' :  // Available For Commission Work
                                                                                            item.Status === 7 ? '#e85342' :      // Deleted
                                                                                                'transparent'
                                                        }}>
                                                            {item.Status === 0 ? 'Waiting for approval' :
                                                                item.Status === 1 ? 'Approved' :
                                                                    item.Status === 2 ? 'Sold Out' :
                                                                        item.Status === 3 ? 'Disabled' :
                                                                            item.Status === 4 ? 'Not approved' :
                                                                                item.Status === 5 ? 'Enable' :
                                                                                    item.Status === 6 ? 'Available For Commission Work' :
                                                                                        item.Status === 7 ? 'Deleted' :
                                                                                            item.Status}
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                ))
                            ) : (
                                <Col xs={12}>  
                                </Col>
                            )}
                        </Row>
                        
                        {filteredSellerList.length > 0 ? (
    <div className="col-lg-12">
        {pagination && (
            <div className="col-lg-12">
                <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                    <div className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a
                            role="button"
                            tabIndex={0}
                            href="#"
                            id="page-prev"
                            className="page-link btn btn-link"
                            onClick={handlePrevPage}
                        >
                            Previous
                        </a>
                    </div>
                    <span id="page-num" className="pagination">
                        {pageNumbers.map(number => (
                            <div
                                key={number}
                                className={`page-item ${number === currentPage ? 'active' : ''}`}
                            >
                                <a
                                    className="page-link clickPageNumber"
                                    id={number.toString()}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCurrentPage(number);
                                    }}
                                >
                                    {number}
                                </a>
                            </div>
                        ))}
                    </span>
                    <div className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                        <a
                            role="button"
                            tabIndex={0}
                            href="#"
                            id="page-next"
                            className="page-link btn btn-link"
                            onClick={handleNextPage}
                        >
                            Next
                        </a>
                    </div>
                </div>
            </div>
        )}
    </div>
) : (
    <div className="text-center py-4">
        <div className="avatar-md mx-auto mb-4">
            <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                <i className="bi bi-search"></i>
            </div>
        </div>
        <h5 className="mt-2">Sorry! No Result Found</h5>
    </div>
)}

                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default GridView;

function setArtist(arg0: never[]) {
    throw new Error('Function not implemented.');
}
