import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import axios from 'axios';

import avatar1 from "assets/images/users/avatar-1.jpg";
import { loginUser } from "../../src/slices/auth/login/thunk";

const ProfileDropdown = () => {
console.log(loginUser, "loginUserloginUserloginUserloginUserloginUserloginUserloginUserloginUser")
const [userName, setUserName] = useState("Robert");
const [userRole, setUserRole] = useState("");
const [allpartners, setAllPartners] = useState<any>(null);
const [loader, setLoader] = useState<boolean>(false);

    const profiledropdownData = createSelector(
        (state) => state.Profile,
        (user) => ({ success: user.success })
    );
    const { success } = useSelector(profiledropdownData);

    useEffect(() => {
        const authUser = localStorage.getItem("authUser");
        console.log(authUser, "authUser");

        if (authUser) {
            const tokenObject = JSON.parse(authUser.replace('Bearer ', ''));
            setUserName(tokenObject.first_name);
            setUserRole(tokenObject.user_role);
        }
    }, []);

const tokenString = localStorage.getItem('authUser');
let token = '';

if (tokenString) {
    const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
    token = tokenObject.token;
}



const logout = async () => {
    console.log("Logging out...");

    setLoader(true);
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASEURL}/auth/signout`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        );

        console.log(response.data, "responseresponse");

       
        localStorage.removeItem('authUser');

    } catch (error) {
        console.error(error);
    } finally {
        setLoader(false);
        window.location.href = '/logout';
    }
};


    return (
        <React.Fragment>
            <Dropdown className="ms-sm-3 header-item topbar-user">
                <Dropdown.Toggle type="button" className="btn bg-transparent border-0 arrow-none" id="page-header-user-dropdown">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1} alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                            <span className="d-none d-xl-block ms-1 fs-13 text-muted user-name-sub-text">{userRole}</span>
                        </span>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {userName}!</h6>
                    <Dropdown.Item href="/user-profile"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Profile</span></Dropdown.Item>
                   
                    <div className="dropdown-divider"></div>
                    {/* <Dropdown.Item href="/pages-profile"><i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Balance : <b>₹8451.36</b></span></Dropdown.Item> */}
                    <Dropdown.Item href="/pages-profile-settings"><i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Settings</span></Dropdown.Item>
                    <Dropdown.Item href="/auth-lockscreen-basic"> 
                  </Dropdown.Item>
                    <Dropdown.Item href="/logout" onClick={logout}><i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span className="align-middle" data-key="t-logout">Logout</span></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
}

export default ProfileDropdown;