import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import deleteicon from 'assets/images/delete icon.png';

interface ArtShowsProps {
    formik: any;
    setArtGallaryAssoc: any;
    setOtherAssoc: any;
    setOnGoingEvents: any;
    setUpcomingEvents: any;
    setExhibitionsEvents: any;
    setCollectioned: any;
}

const ArtShows: React.FC<ArtShowsProps> = ({ formik, setArtGallaryAssoc, setOtherAssoc, setOnGoingEvents, setUpcomingEvents, setExhibitionsEvents, setCollectioned }) => {
    const { values, errors, touched, setFieldValue } = formik;

    const handleArrayChange = (arrayName: string, index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedArray = [...values[arrayName]];
        updatedArray[index].name = event.target.value;
        setFieldValue(arrayName, updatedArray);
    };

    const handleAddItem = (arrayName: string) => {
        const updatedArray = [...values[arrayName], { name: '' }];
        setFieldValue(arrayName, updatedArray);
    };

    const handleRemoveItem = (arrayName: string, index: number) => {
        const updatedArray = [...values[arrayName]];
        updatedArray.splice(index, 1);
        setFieldValue(arrayName, updatedArray);
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    <h5 className="fs-16">Art Shows</h5>
                    <p className="text-muted mb-lg-0">Personal Identifiable Information (PII) is defined as: Any representation of information that permits the identity of an individual to whom the information applies to be reasonably inferred by either direct or indirect means.</p>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Row className="g-3">
                                <Col lg={12}>
                                    <Form.Label>Association With Art Gallery</Form.Label>
                                    {values.art_gallary_assoc.map((education: { name: string }, index: number) => (
                                        <div className="d-flex mb-2">
                                            <div key={index} style={{ paddingBottom: '10px', width: '100%' }}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='Enter association with art gallery'
                                                    value={education.name}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArrayChange('art_gallary_assoc', index, e)}
                                                    isInvalid={!!errors.art_gallary_assoc?.[index] && touched.art_gallary_assoc?.[index]}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.art_gallary_assoc && errors.art_gallary_assoc[index] ? errors.art_gallary_assoc[index].name : ''}
                                                </Form.Control.Feedback>
                                            </div>

                                            <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                <Button variant="link" onClick={() => handleRemoveItem('art_gallary_assoc', index)}>
                                                    <img src={deleteicon} alt="delete" height={18} width={18} />
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    <Button variant="outline-primary" onClick={() => handleAddItem('art_gallary_assoc')}>
                                        + Add Art Gallery
                                    </Button>
                                </Col>

                                <Col lg={12}>
                                    <Form.Label>other Association</Form.Label>
                                    {values.other_assoc.map((education: { name: string }, index: number) => (
                                        <div key={index} className="d-flex   mb-2">
                                            <div style={{ paddingBottom: '10px', width: '100%' }}>
                                                <Form.Control
                                                    type="text"
                                                    value={education.name}
                                                    placeholder='Enter other association'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArrayChange('other_assoc', index, e)}
                                                    isInvalid={!!errors.other_assoc?.[index] && touched.other_assoc?.[index]}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.other_assoc && errors.other_assoc[index] ? errors.other_assoc[index].name : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                <Button variant="link" onClick={() => handleRemoveItem('other_assoc', index)}>
                                                    <img src={deleteicon} alt="delete" height={18} width={18} />
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    <Button variant="outline-primary" onClick={() => handleAddItem('other_assoc')}>
                                        + Add Other Associations
                                    </Button>
                                </Col>

                                <Col lg={12}>
                                    <Form.Label>Ongoing Events</Form.Label>
                                    {values.ongoing_events.map((education: { name: string }, index: number) => (
                                        <div key={index} className="d-flex   mb-2">
                                            <div style={{ paddingBottom: '10px', width: '100%' }}>
                                                <Form.Control
                                                    type="text"
                                                    value={education.name}
                                                    placeholder='Enter ongoing event'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArrayChange('ongoing_events', index, e)}
                                                    isInvalid={!!errors.ongoing_events?.[index] && touched.ongoing_events?.[index]}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.ongoing_events && errors.ongoing_events[index] ? errors.ongoing_events[index].name : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                <Button variant="link" onClick={() => handleRemoveItem('ongoing_events', index)}>
                                                    <img src={deleteicon} alt="delete" height={18} width={18} />
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    <Button variant="outline-primary" onClick={() => handleAddItem('ongoing_events')}>
                                        + Add Ongoing Events
                                    </Button>
                                </Col>

                                <Col lg={12}>
                                    <Form.Label>Upcoming Events</Form.Label>
                                    {values.upcoming_events.map((education: { name: string }, index: number) => (
                                        <div key={index} className="d-flex   mb-2">
                                            <div style={{ paddingBottom: '10px', width: '100%' }}>
                                                <Form.Control
                                                    type="text"
                                                    value={education.name}
                                                    placeholder='Enter upcoming event'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArrayChange('upcoming_events', index, e)}
                                                    isInvalid={!!errors.upcoming_events?.[index] && touched.upcoming_events?.[index]}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.upcoming_events && errors.upcoming_events[index] ? errors.upcoming_events[index].name : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                <Button variant="link" onClick={() => handleRemoveItem('upcoming_events', index)}>
                                                    <img src={deleteicon} alt="delete" height={18} width={18} />
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    <Button variant="outline-primary" onClick={() => handleAddItem('upcoming_events')}>
                                        + Add Upcoming Events
                                    </Button>
                                </Col>

                                <Col lg={12}>
                                    <Form.Label>Exihibition's</Form.Label>
                                    {values.exhibitions_events.map((education: { name: string }, index: number) => (
                                        <div key={index} className="d-flex   mb-2">
                                            <div style={{ paddingBottom: '10px', width: '100%' }}>
                                                <Form.Control
                                                    type="text"
                                                    value={education.name}
                                                    placeholder='Enter Exihibition'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArrayChange('exhibitions_events', index, e)}
                                                    isInvalid={!!errors.exhibitions_events?.[index] && touched.exhibitions_events?.[index]}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.exhibitions_events && errors.exhibitions_events[index] ? errors.exhibitions_events[index].name : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                <Button variant="link" onClick={() => handleRemoveItem('exhibitions_events', index)}>
                                                    <img src={deleteicon} alt="delete" height={18} width={18} />
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    <Button variant="outline-primary" onClick={() => handleAddItem('exhibitions_events')}>
                                        + Add Exihibition
                                    </Button>
                                </Col>

                                <Col lg={12}>
                                    <Form.Label>Collection's</Form.Label>
                                    {values.collection.map((education: { name: string }, index: number) => (
                                        <div key={index} className="d-flex   mb-2">
                                            <div style={{ paddingBottom: '10px', width: '100%' }}>
                                                <Form.Control
                                                    type="text"
                                                    value={education.name}
                                                    placeholder='Enter collection'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArrayChange('collection', index, e)}
                                                    isInvalid={!!errors.collection?.[index] && touched.collection?.[index]}
                                                />
                                                <Form.Control.Feedback type="invalid" className='text-danger'>
                                                    {errors.collection && errors.collection[index] ? errors.collection[index].name : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                <Button variant="link" onClick={() => handleRemoveItem('collection', index)}>
                                                    <img src={deleteicon} alt="delete" height={18} width={18} />
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    <Button variant="outline-primary" onClick={() => handleAddItem('collection')}>
                                        + Add Collection
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default ArtShows;