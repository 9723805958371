import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Tabs, Tab, Table } from 'react-bootstrap';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { useLocation } from 'react-router-dom';

const ArtworkDetails = () => {
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState('gallery');
  const [loader, setLoader] = useState(false);
  const [sellerList, setSellerList] = useState([]);
  const location = useLocation();
  const { data: id } = location.state || {}; // Destructure the id from state

  console.log('Received ID:', id); 

  const handleDisableArtwork = () => {
    setShowDisableModal(false);
  };

  const handleDeleteArtwork = () => {
    setShowDeleteModal(false);
  };

  const fetchRolesList = async () => {
    setLoader(true);
    try {
      const tokenString = localStorage.getItem('authUser');
      let token = '';

      if (tokenString) {
        const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
        token = tokenObject.token;
      }

const response = await axios.get(
  `${process.env.REACT_APP_API_BASEURL}/auth/get_all_artwork_by_id`, 
  {
    params: { id }, 
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }
);

      setSellerList(response.data);
    } catch (error) {
      console.error("Error fetching seller list:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchRolesList();
  }, []);
 
  console.log()
  console.log(JSON.stringify(sellerList, null, 2), "Reeeeeeeeeeesponse data");

  return (
    <div className="artwork-details">
      <Row>
        {/* Artwork Image Column */}
        <Col md={6} lg={5}>
          {sellerList.map((item:any) => (
            <div className="box-body" key={item.id}>
              <img
                src={item.Artwork_image}
                alt="Artwork Thumbnail"
                style={{ width: '100%' }}
              />
            </div>
          ))}
        </Col>

        {/* Artwork Details Column */}
        <Col md={6} lg={7}>
        {sellerList.map((item:any) => (
          <div className="artwork-info">
            <h3>{item.Title}</h3>
            <p>{item.Description}</p>
            <p>
              <strong>Category:</strong> <a href="#">{item.category_name}</a> <br />
              <strong>Mediums: </strong> <a href="#">{item.mediums_name}</a> <br />
              <strong>Size: </strong> 36 X 60
            </p>
            <p>
              <a className="label label-success" href="#">
                {item.Label}
              </a>{' '}
              {/* <a className="label label-success" href="#">
                Folk
              </a>{' '}
              <a className="label label-success" href="#">
                Canvas
              </a>{' '}
              <a className="label label-success" href="#">
                Brown
              </a> */}
            </p>
            <p>
            <span>Original Sale</span>
{item.Is_available_for_original_sale === 'yes' ? (
  <span style={{ marginLeft: '5px' }}>
    <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
  </span>
) : (
  <span style={{ marginLeft: '5px' }}>
    <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
  </span>
)}

              <span style={{ marginLeft: '15px' }}>Print Sale</span>
              {/* <span style={{ marginLeft: '5px' }}>
                <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
              </span> */}


{item.Is_available_for_print_sale === 'yes' ? (
  <span style={{ marginLeft: '5px' }}>
    <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
  </span>
) : (
  <span style={{ marginLeft: '5px' }}>
    <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
  </span>
)}

              <span style={{ marginLeft: '15px' }}>Desktop Art Sale</span>
              {/* <span style={{ marginLeft: '5px' }}>
                <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
              </span> */}
{item.Is_vailable_for_desktop_art === 'yes' ? (
  <span style={{ marginLeft: '5px' }}>
    <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
  </span>
) : (
  <span style={{ marginLeft: '5px' }}>
    <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
  </span>
)}

            </p>

            {/* Edit Artwork Button */}
            <a href="https://admin.artflute.com/admin/artwork/14124/edit" className="btn btn-success">
              <i className="fa fa-pencil"></i> Edit this Artwork
            </a>

            {/* Disable Artwork Button */}
            <Button variant="primary" className="ladda-button" onClick={() => setShowDisableModal(true)}>
              Disable this Artwork
            </Button>

            {/* Delete Artwork Button */}
            <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
              Delete this Artwork
            </Button>
          </div>
            ))}
        </Col>
      </Row>

      {/* Tabs for Gallery and Other Sections Positioned Below */}
      <div className="artwork-tabs mt-4">
        <Tabs
          id="artwork-details-tabs"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k || 'gallery')}
          className="mb-3"
        >
          <Tab eventKey="gallery" title="Gallery">
          
            <Button
              className="btn btn-primary ladda-button mb-3"
              onClick={() => alert('Add Artwork Image Modal Triggered')}
            >
              Add Artwork Image
            </Button>

           
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Image</th>
                  <th>Dimensions</th>
                  <th className="actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sellerList.map((item:any) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      <img
                        src={item.Artwork_image}
                        alt="Thumbnail"
                        style={{ width: '50px' }}
                      />
                    </td>
                    <td>{item.dimensions}</td>
                    <td>
                      <Button variant="danger" onClick={() => alert('Delete Action')}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab>

          <Tab eventKey="highResolution" title="High Resolution Images">
            <div className="tab-content">
              <Button className="btn btn-primary ladda-button" onClick={() => alert('Add High-Res Image')}>
                Add High-Res Image
              </Button>

              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Image</th>
                    <th>Dimensions</th>
                    <th className="actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                 
                  <tr>
                    <td>22639</td>
                    <td>
                      <img
                        style={{ height: '240px', width: '340px!important' }}
                        src="https://s3.ap-southeast-1.amazonaws.com/art-flute-media/artworks/artwork-/thumbnail/compressed/31_08_24_01_13_00_IMG20230825181002.jpg"
                        alt="High Resolution Artwork"
                      />
                    </td>
                    <td>
                      <p><strong>Is high resolution?</strong> No</p>
                      <p><strong>Width: </strong></p>
                      <p><strong>Height: </strong></p>
                      <p><strong>Pixel: </strong></p>
                    </td>
                    <td className="item-actions">
                      <a href="https://admin.artflute.com/admin/artworkimage/22639/edit">Edit</a>
                      <a href="https://admin.artflute.com/admin/artworkimage/22639" data-button-type="delete">Delete</a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Tab>

          <Tab eventKey="printavailability" title="Print Availability">
            <div className="tab-content">
              <Button className="btn btn-primary ladda-button mb-3" onClick={() => alert('Add Print Availability')}>
                Add Print Availability
              </Button>

              {/* Table for print availability */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Criteria</th>
                    <th>Price - INR</th>
                    <th>Price - USD</th>
                    <th className="actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Table rows will go here */}
                </tbody>
              </Table>
            </div>
          </Tab>

          <Tab eventKey="commentsReviews" title="Comments/Reviews">
            <div className="tab-content">
              {/* Table for comments and reviews */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Comment/Review</th>
                    <th>User</th>
                    <th className="actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Table rows will go here */}
                </tbody>
              </Table>
            </div>
          </Tab>

          <Tab eventKey="activitylog" title="Activity log">
            <div className="tab-content">
              <p>
                <a className="show-more" href="/admin/audit/feed/96232f7b-0db9-419e-808b-a5787fc73303">
                  show more
                </a>
              </p>
              <p>
                <a href="/admin/userCustomer/302" target="_blank">User Details</a>
              </p>
            </div>
          </Tab>
        </Tabs>
      </div>

      {/* Disable Artwork Modal */}
      <Modal show={showDisableModal} onHide={() => setShowDisableModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Disable Artwork</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to disable this artwork?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDisableModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDisableArtwork}>
            Disable
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Artwork Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Artwork</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this artwork?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteArtwork}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ArtworkDetails;