import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import axios from 'axios';
import withRouter from "Common/withRouter";
import Breadcrumb from 'Common/BreadCrumb';
import avatar from "../../assets/images/users/avatar-1.jpg";
import { editProfile, resetProfileFlag } from "slices/thunk";

const UserProfile = () => {
    document.title = "User Profile | Artflute + Admin React Template";
    const dispatch = useDispatch<any>();
    const [email, setemail] = useState("admin@gmail.com");
    const [name, setname] = useState("Admin");
    const [idx, setidx] = useState("1");
    const [loader, setLoader] = useState<boolean>(false);

    const tokenString = localStorage.getItem('authUser');
    let token = '';
    let emaill = '';
    let id = '';
let defalutname='';
    if (tokenString) {
        const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
        token = tokenObject.token;
        emaill = tokenObject.email;
        id = tokenObject.id;
        defalutname=tokenObject.first_name
    }

    const selectLayoutState = (state: any) => state.Profile;
    const selectProperties = createSelector(
        selectLayoutState,
        (state: any) => ({
            user: state.user,
            success: state.success,
            error: state.error
        })
    );

    const { error, success } = useSelector(selectProperties);

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser") || "{}");
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName);
                setemail(obj.email);
                setidx(obj.uid);
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                setname(obj.username);
                setemail(obj.email);
                setidx(obj.uid);
            }

            setTimeout(() => {
                dispatch(resetProfileFlag());
            }, 3000);
        }
    }, [dispatch, success, error]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: defalutname || '',
            id: id,
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("Please Enter Your UserName"),
        }),
        onSubmit: async (values) => {
            try {
                setLoader(true);
                const response = await axios.post(
                    `${process.env.REACT_APP_API_BASEURL}/auth/edit_admin_name`,
                    values,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    }
                );
                console.log(response.data, "Successfully updated user name");
                localStorage.removeItem('authUser');
                window.location.href = '/logout';
            } catch (error) {
                console.error("Error while updating user name", error);
            } finally {
                setLoader(false);
            }
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="Profile" pageTitle="Profile" />
                    <Row>
                        <Col lg="12">
                        {error && error ? <Alert variant="danger">{error}</Alert> : null}
                        {success ? <Alert variant="success">{success}</Alert> : null}
                            <Card>
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mx-3">
                                            <img
                                                src={avatar}
                                                alt=""
                                                className="avatar-md rounded-circle img-thumbnail"
                                            />
                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="text-muted">
                                                <h5>{name}</h5>
                                                <p className="mb-1">{emaill}</p>
                                                <p className="mb-0">Id no: #{id}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <h4 className="card-title mb-4">Change User Name</h4>

                    <Card>
                        <Card.Body>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="form-group">
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control
                                        name="first_name"
                                        className="form-control"
                                        placeholder="Enter User Name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.first_name || ""}
                                        isInvalid={
                                            validation.touched.first_name && validation.errors.first_name ? true : false
                                        }
                                    />
                                    {validation.touched.first_name && validation.errors.first_name ? (
                                        <Form.Control.Feedback type="invalid">
                                            {validation.errors.first_name}
                                        </Form.Control.Feedback>
                                    ) : null}
                                    <Form.Control name="id" value={id} type="hidden" />
                                </div>
                                <div className="text-center mt-4">
                                    <Button type="submit" variant="danger" disabled={loader}>
                                        {loader ? "Updating..." : "Update User Name"}
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(UserProfile);
