import deleteicon from 'assets/images/delete icon.png';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';

interface PersonalInformationProps {
    formik: any;
    setEducations: any;
    setAwards: any;
    setDisciplines: any;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({ formik, setEducations, setAwards, setDisciplines }) => {
    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = formik;
    const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);


    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        if (event.target.files && event.target.files[0]) {
            try {
                let data: any = new FormData();
                data.append('fileType', 'artist_thumb_desktop');
                data.append('filePath', event.target.files[0]);

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_BASEURL}/auth/uploadfile`,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data.mediaPaths[0].filePath));
                        setFieldValue(fieldName, response.data.mediaPaths[0].filePath);
                        console.log('imageData', response.data.mediaPaths[0].filePath)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Error on upload image ", error);
            }
        }
    };

    const handleArrayChange = (arrayName: string, index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedArray = [...values[arrayName]];
        updatedArray[index].name = event.target.value;
        setFieldValue(arrayName, updatedArray);
    };

    const handleAddItem = (arrayName: string) => {
        const updatedArray = [...values[arrayName], { name: '' }];
        setFieldValue(arrayName, updatedArray);
    };

    const handleRemoveItem = (arrayName: string, index: number) => {
        const updatedArray = [...values[arrayName]];
        updatedArray.splice(index, 1);
        setFieldValue(arrayName, updatedArray);
    };

    return (
        <React.Fragment>
            {showSuccessAlert && (
                <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                    Partner added successfully!
                </Alert>
            )}
            <Row>
                <Col lg={4}>
                    <h5 className="fs-16">Personal Information</h5>
                    <p className="text-muted mb-lg-0">Personal Identifiable Information (PII) is defined as: Any representation of information that permits the identity of an individual to whom the information applies to be reasonably inferred by either direct or indirect means.</p>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row className="g-3">
                                    <Col lg={6}>
                                        <Form.Group controlId="firstName">
                                            <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="first_name"
                                                placeholder="Enter your first name"
                                                value={values.first_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.first_name && touched.first_name}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.first_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="lastName">
                                            <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="last_name"
                                                placeholder="Enter your last name"
                                                value={values.last_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.last_name && touched.last_name}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.last_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="emailInput">
                                            <Form.Label>Email Address <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                placeholder="name@toner.com"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email && touched.email}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="phoneInput">
                                            <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="phone"
                                                placeholder="Enter phone number"
                                                value={values.phone}
                                                onChange={handleChange}
                                                isInvalid={!!errors.phone && touched.phone}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.phone}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="password">
                                            <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="Enter Password"
                                                value={values.password}
                                                onChange={handleChange}
                                                isInvalid={!!errors.password && touched.password}
                                                autoComplete="password"
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="confirm_password">
                                            <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="confirm_password"
                                                placeholder="Confirm Password"
                                                onChange={handleChange}
                                                isInvalid={!!errors.confirm_password && touched.confirm_password}
                                                autoComplete="confirm_password"
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.confirm_password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="genderSelect">
                                            <Form.Label>Gender <span className="text-danger">*</span></Form.Label>
                                            <Form.Select
                                                name="gender"
                                                value={values.gender || ""}
                                                onChange={handleChange}
                                                isInvalid={!!errors.gender && touched.gender}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.gender}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="profileInput">
                                            <Form.Label>Profile </Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="profile"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "profile")}
                                                isInvalid={!!errors.profile && touched.profile}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.profile}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="cityInput">
                                            <Form.Label>City <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="city"
                                                placeholder="Enter city"
                                                value={values.city}
                                                onChange={handleChange}
                                                isInvalid={!!errors.city && touched.city}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.city}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="countryInput">
                                            <Form.Label>Country <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="country"
                                                placeholder="Enter country"
                                                value={values.country}
                                                onChange={handleChange}
                                                isInvalid={!!errors.country && touched.country}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.country}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="zipCodeInput">
                                            <Form.Label>Zip Code <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="zip_code"
                                                placeholder="Enter zip code"
                                                value={values.zip_code}
                                                onChange={handleChange}
                                                isInvalid={!!errors.zip_code && touched.zip_code}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.zip_code}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="selection_of_enq_original">
                                            <Form.Label>Selection Of Enquiry</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="selection_of_enq_original"
                                                placeholder="Enter enquiry"
                                                value={values.selection_of_enq_original}
                                                onChange={handleChange}
                                                isInvalid={!!errors.selection_of_enq_original && touched.selection_of_enq_original}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.selection_of_enq_original}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="comission_work_for_artist">
                                            <Form.Label>Commission work</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="comission_work_for_artist"
                                                placeholder="Enter commission work"
                                                value={values.comission_work_for_artist}
                                                onChange={handleChange}
                                                isInvalid={!!errors.comission_work_for_artist && touched.comission_work_for_artist}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.comission_work_for_artist}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="thumbDesktopInput">
                                            <Form.Label>Artist Thumb (Desktop)</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="artist_thumb_desktop"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "artist_thumb_desktop")}
                                                isInvalid={!!errors.artist_thumb_desktop && touched.artist_thumb_desktop}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.artist_thumb_desktop}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="thumbMobileInput">
                                            <Form.Label>Artist Thumb (Mobile)</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="artist_thumb_mobile"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event, "artist_thumb_mobile")}
                                                isInvalid={!!errors.artist_thumb_mobile && touched.artist_thumb_mobile}
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-danger'>
                                                {errors.artist_thumb_mobile}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Label>Artist Education</Form.Label>
                                        {values.artist_education.map((education: { name: string }, index: number) => (
                                            <div key={index} className="d-flex mb-2">
                                                <div style={{ paddingBottom: '10px', width: '100%' }}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Enter artist education`}
                                                        value={education.name}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArrayChange('artist_education', index, e)}
                                                        isInvalid={!!errors.artist_education?.[index] && touched.artist_education?.[index]}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.artist_education && errors.artist_education[index] ? errors.artist_education[index].name : ''}
                                                    </Form.Control.Feedback>
                                                </div>
                                                <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                    <Button variant="link" onClick={() => handleRemoveItem('artist_education', index)}>
                                                        <img src={deleteicon} alt="delete" height={18} width={18} />
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                        <Button variant="outline-primary" onClick={() => handleAddItem('artist_education')}>
                                            + Add Education
                                        </Button>
                                    </Col>
                                    <Col lg={12} className="my-2">
                                        <Form.Label>Artist Awards & Recognition</Form.Label>
                                        {values.artist_awards_recog.map((award: { name: string }, index: number) => (
                                            <div key={index} className="d-flex mb-2">
                                                <div style={{ paddingBottom: '10px', width: '100%' }}>
                                                    <Form.Control
                                                        type="text"
                                                        value={award.name}
                                                        placeholder='Enter artist awards & recognition'
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArrayChange('artist_awards_recog', index, e)}
                                                        isInvalid={!!errors.artist_awards_recog?.[index] && touched.artist_awards_recog?.[index]}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.artist_awards_recog && errors.artist_awards_recog[index] ? errors.artist_awards_recog[index].name : ''}
                                                    </Form.Control.Feedback>
                                                </div>
                                                <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                    <Button variant="link" onClick={() => handleRemoveItem('artist_awards_recog', index)}>
                                                        <img src={deleteicon} alt="delete" height={18} width={18} />
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                        <Button variant="outline-primary" onClick={() => handleAddItem('artist_awards_recog')}>
                                            + Add Award
                                        </Button>
                                    </Col>
                                    <Col lg={12} className="my-0">
                                        <Form.Label>Artist Disciplines</Form.Label>
                                        {values.artist_desciplines.map((discipline: { name: string }, index: number) => (
                                            <div key={index} className="d-flex mb-2">
                                                <div style={{ paddingBottom: '10px', width: '100%' }}>
                                                    <Form.Control
                                                        type="text"
                                                        value={discipline.name}
                                                        placeholder='Enter artist disciplines'
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleArrayChange('artist_desciplines', index, e)}
                                                        isInvalid={!!errors.artist_desciplines?.[index] && touched.artist_desciplines?.[index]}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className='text-danger'>
                                                        {errors.artist_desciplines && errors.artist_desciplines[index] ? errors.artist_desciplines[index].name : ''}
                                                    </Form.Control.Feedback>
                                                </div>
                                                <div style={{ paddingBottom: '10px', width: '10%', textAlign: 'center' }}>
                                                    <Button variant="link" onClick={() => handleRemoveItem('artist_desciplines', index)}>
                                                        <img src={deleteicon} alt="delete" height={18} width={18} />
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                        <Button variant="outline-primary" onClick={() => handleAddItem('artist_desciplines')}>
                                            + Add Discipline
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default PersonalInformation;