import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Col, Form as BootstrapForm, Tabs, Tab, Button } from "react-bootstrap";
import * as Yup from 'yup';

const validationSchema = Yup.object({
  username: Yup.string().required('Username is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Confirm password is required'),
  category: Yup.string().required('Category is required'),
  mediums: Yup.string().required('Mediums is required'),
  artStyle: Yup.string().required('Art Style is required'),
  surface: Yup.string().required('Surface is required'),
  shippedAs: Yup.string().required('Shipped As is required'),
  users_status: Yup.string().required('Status is required'),
  Timelines: Yup.string().required('Timelines is required'),
  Year_released: Yup.string().required('Year Released is required'),
  Colors: Yup.string().required('Colors is required'),
  Tags: Yup.string().required('Tags is required'),
  Signature: Yup.string().required('Signature is required'),
  Palette: Yup.string().required('Palette is required'),
  detailsField1: Yup.string().required('Details Field 1 is required'),
  detailsField2: Yup.string().required('Details Field 2 is required'),
  tab1Field: Yup.string().required('Tab 1 Field is required'),
  tab2Field: Yup.string().required('Tab 2 Field is required'),
  tab3Field: Yup.string().required('Tab 3 Field is required'),
});

const SignupForm = () => {
  const handleSubmit = (values: any) => {
    console.log('Form Data:', values);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = ({e}:any) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div className="page-content">
      <Formik
        initialValues={{
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
          category: '',
          mediums: '',
          artStyle: '',
          surface: '',
          shippedAs: '',
          users_status: '',
          Timelines: '',
          Year_released: '',
          Colors: '',
          Tags: '',
          Signature: '',
          Palette: '',
          Edition_Size:'',
          art_type:'',
          art_subject:'',
          seo_title:'',
          seo_keyword:'',
          seo_description:'',
          detailsField1: '',
          detailsField2: '',
          tab1Field: '',
          tab2Field: '',
          tab3Field: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleChange, handleBlur, values, touched, errors }) => (
          <Form>
            <Tabs defaultActiveKey="category" id="form-tabs" className="mb-3">



            <Tab eventKey="Artwork" title="Artwork">
                {/* Tab 2 Field */}


                <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Artist</label>
      <select 
        className="form-control" 
        name="price_on_request" 
        value="0"
      >
        <option value="0" selected>Ariba</option>
        <option value="1759">A Ramachandran</option>
                

                <option value="1923">A. Viswam</option>
                  
  
                <option value="1360">AB UTNAL</option>
                  
  
                <option value="869">ABHRODEEP MUKHERJEE</option>
                  
  
                <option value="870">ABHRODEEP MUKHERJEE</option>
                  
  
                <option value="701">AKS CHAUHAN</option>
                  
  
                <option value="702">AKS Chauhan</option>
                  
  
                <option value="1682">ANIRBAN SETH</option>
      </select>
    </div>
                <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Label</label>
      <input 
        type="text" 
        name="label" 
        value="" 
        className="form-control" 
      />
    </div>
    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
    <label>Title             <span style={{ display: 'red' }}>*</span>
        
        
    </label>

                    <input  type="text" name="title" value="Ryomen sukuna" className="form-control" id="title"/>
            
    
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
    <label>Slug 
        
    </label>

                    <input type="text" name="slug" value="ryomen-sukuna" className="form-control" id="slug"/>
            
    
    </div>




    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
    <label>Description
                    </label>
    <textarea name="description" className="form-control">This is the best piece of artwork i have ever created.. 
    
    </textarea>

    
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Price On Request</label>
      <select 
        className="form-control" 
        name="price_on_request" 
        value="0"
      >
        <option value="0" selected>No</option>
        <option value="1">Yes</option>
      </select>
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Limited period discount</label>
      <select 
        className="form-control" 
        name="price_on_request" 
        value="0"
      >
        <option value="0" selected>No</option>
        <option value="1">Yes</option>
      </select>
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Sold out?</label>
      <select 
        className="form-control" 
        name="price_on_request" 
        value="0"
      >
        <option value="0" selected>No</option>
        <option value="1">Yes</option>
      </select>
    </div>

    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Is Available for print sale?</label>
      <select 
        className="form-control" 
        name="price_on_request" 
        value="0"
      >
        <option value="0" selected>No</option>
        <option value="1">Yes</option>
      </select>
    </div>



    



    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>
        Artwork Image <span style={{ color: 'red' }}>*</span>
      </label>

      <div className="img-wrapper" style={{ marginBottom: '30px', width: '180px' }}>
        <img
          className="selected-image"
          src="https://s3.ap-southeast-1.amazonaws.com/art-flute-media/artworks/artwork-/thumbnail/compressed/10_06_24_06_36_04_IMG_20230526_183457_597.jpg"
          alt=""
          style={{ width: '100%' }}
        />
        <input
          type="hidden"
          name="image_link"
          value="https://s3.ap-southeast-1.amazonaws.com/art-flute-media/artworks/artwork-/thumbnail/compressed/10_06_24_06_36_04_IMG_20230526_183457_597.jpg"
          className="image-link"
        />
        <ul className="img-list"></ul>
      </div>

      <input
        type="file"
        className="image-upload form-control file-not-so"
        name="thumbnail_upload"
        onChange={handleFileChange}
      />
    </div>

    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Artwork Webp Image</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>


    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Artwork Full Webp Image</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>


    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Artwork Full Image *</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>


    <div className="form-group col-md-12 content-artwork"  style={{ display: 'block' }}>
    <label>Originals price 
        
    </label>

                    <input type="text" name="originals_price" value="300" className="form-control"/>
            
    
    </div>



    <div className="form-group col-md-12 content-artwork"  style={{ display: 'block' }}>
    <label>Originals price dollar 
        
    </label>

                    <input type="text" name="originals_price" value="" className="form-control"/>
            
    
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Status</label>
      <select className="form-control" name="status" defaultValue="5">
        <option value="0">Waiting for approval</option>
        <option value="1">Approved</option>
        <option value="2">Sold Out</option>
        <option value="3">Disabled</option>
        <option value="4">Deleted</option>
        <option value="5">Not approved</option>
        <option value="6">Available For Commission Work</option>
      </select>
    </div>

    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
    <label>Creation place 
        
    </label>

                    <input type="text" name="creation_place" value="" className="form-control"/>
            
    
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
    <label>Weight(in Kg) 
        
    </label>

                    <input type="text" name="weight" value="0" className="form-control"/>
            
    
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
    <label>Dimension Depth 
        
    </label>

                    <input type="text" name="dimension_height" value="" className="form-control"/>
            
    
    </div>

    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
    <label>Dimension Width             <span style={{ display: 'red' }}>*</span>
        
        
    </label>

                    <input  type="text" name="dimension_breadth" value="8.27" className="form-control"/>
            
    
    </div>

    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
    <label>Dimension Height             <span style={{ display: 'red' }}>*</span>
        
        
    </label>

                    <input  type="text" name="dimension_length" value="11.6" className="form-control"/>
            
    
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Is Available for original sale?</label>
      <select 
        className="form-control" 
        name="price_on_request" 
        value="0"
      >
        <option value="0" selected>No</option>
        <option value="1">Yes</option>
      </select>
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Is Available for desktop art?</label>
      <select 
        className="form-control" 
        name="price_on_request" 
        value="0"
      >
        <option value="0" selected>No</option>
        <option value="1">Yes</option>
      </select>
    </div>


    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>
        Orientation
        <span style={{ color: 'red' }}>*</span>
      </label>
      <select
        className="form-control"
        name="orientation"
        required
      >
        <option value="0">Square</option>
        <option value="1" selected>Horizontal</option>
        <option value="2">Vertical</option>
      </select>
    </div>
    <div className="form-group col-md-12 content-artwork" style={{ display: 'block' }}>
      <label>Label For Date</label>
      <input
        type="date"
        name="label_for_date"
        value=""
        className="form-control"
      />
    </div>
    <div className="form-group col-md-12 content-artwork"  style={{ display: 'block' }}>
    <label>Year 
        
    </label>

                    <input type="number" name="year" value="2022" className="form-control"/>
        
    
    
    </div>
    <div className="form-group col-md-12 content-artwork" style={{ display: 'block', padding:'10px' }}>
      <label>Month</label>
      <select className="form-control" name="month">
        <option value="1">Jan</option>
        <option value="2">Feb</option>
        <option value="3">Mar</option>
        <option value="4">Apr</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7" selected>
          July
        </option>
        <option value="8">Aug</option>
        <option value="9">Sep</option>
        <option value="10">Oct</option>
        <option value="11">Nov</option>
        <option value="12">Dec</option>
      </select>
    </div>
              </Tab>

              <Tab eventKey="category" title="Categories/Tags">
                {/* Category Dropdown */}
                <div className="form-group col-md-12 content-category">
                  <label>
                    Category <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Field as="select" name="category" className="form-control artwork-category">
                    <option value="">Choose</option>
                    <option value="1">Painting</option>
                    <option value="2">Sculpture</option>
                  </Field>
                  <ErrorMessage name="category" component="div" className="text-danger" />
                  
                </div>

             
                {/* Status Dropdown */}
                <Col md={6}>
                  <BootstrapForm.Group controlId="users_status">
                    <BootstrapForm.Label>Status</BootstrapForm.Label>
                    <BootstrapForm.Control
                      as="select"
                      name="users_status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.users_status || ""}
                      isInvalid={touched.users_status && !!errors.users_status}
                    >
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </BootstrapForm.Control>
                    <ErrorMessage name="users_status" component="div" className="text-danger" />
                  </BootstrapForm.Group>
                </Col>

                {/* Mediums Dropdown */}
                <Col md={6}>
                  <BootstrapForm.Group controlId="mediums">
                    <BootstrapForm.Label>Mediums</BootstrapForm.Label>
                    <BootstrapForm.Control
                      as="select"
                      name="mediums"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mediums || ""}
                      isInvalid={touched.mediums && !!errors.mediums}
                    >
                      <option value="">Select Medium</option>
                      <option value="1">Pen and Ink</option>
                      <option value="2">Acrylic</option>
                      <option value="3">Encaustic</option>
                    </BootstrapForm.Control>
                    <ErrorMessage name="mediums" component="div" className="text-danger" />
                  </BootstrapForm.Group>
                </Col>

                
                


                <Col md={6}>
                  <BootstrapForm.Group controlId="art_type">
                    <BootstrapForm.Label>Art Type
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      as="select"
                      name="art_type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.art_type || ""}
                      isInvalid={touched.art_type && !!errors.art_type}
                    >
                      <option value="">Select art_type</option>
                      <option value="1">Pen and Ink</option>
                      <option value="2">Acrylic</option>
                      <option value="3">Encaustic</option>
                    </BootstrapForm.Control>
                    <ErrorMessage name="art_type" component="div" className="text-danger" />
                  </BootstrapForm.Group>
                </Col>


                
                
                
                

                <Col md={6}>
                  <BootstrapForm.Group controlId="art_subject">
                    <BootstrapForm.Label>Art Subject
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      as="select"
                      name="art_subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.art_subject || ""}
                      isInvalid={touched.art_subject && !!errors.art_subject}
                    >
                      <option value="">Select art_subject</option>
                      <option value="1">Pen and Ink</option>
                      <option value="2">Acrylic</option>
                      <option value="3">Encaustic</option>
                    </BootstrapForm.Control>
                    <ErrorMessage name="art_subject" component="div" className="text-danger" />
                  </BootstrapForm.Group>
                </Col>

                {/* Art Style Dropdown */}
                <Col md={6}>
                  <BootstrapForm.Group controlId="artStyle">
                    <BootstrapForm.Label>Art Style</BootstrapForm.Label>
                    <BootstrapForm.Control
                      as="select"
                      name="artStyle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.artStyle || ""}
                      isInvalid={touched.artStyle && !!errors.artStyle}
                    >
                      <option value="">Select Art Style</option>
                      <option value="1">Abstract</option>
                      <option value="2">Figurative</option>
                      <option value="52">Folk</option>
                      <option value="53">Decorative</option>
                      <option value="54">Cubism</option>
                      <option value="55">Realism</option>
                      <option value="56">Impressionism</option>
                    </BootstrapForm.Control>
                    <ErrorMessage name="artStyle" component="div" className="text-danger" />
                  </BootstrapForm.Group>
                </Col>

                {/* Palette Dropdown */}
                <Col md={6}>
                  <BootstrapForm.Group controlId="Palette">
                    <BootstrapForm.Label>Palette</BootstrapForm.Label>
                    <BootstrapForm.Control
                      as="select"
                      name="Palette"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Palette || ""}
                      isInvalid={touched.Palette && !!errors.Palette}
                    >
                      <option value="">Select Palette</option>
                      <option value="1">Abstract</option>
                      <option value="2">Figurative</option>
                      <option value="52">Folk</option>
                      <option value="53">Decorative</option>
                      <option value="54">Cubism</option>
                      <option value="55">Realism</option>
                      <option value="56">Impressionism</option>
                    </BootstrapForm.Control>
                    <ErrorMessage name="Palette" component="div" className="text-danger" />
                  </BootstrapForm.Group>
                </Col>

                {/* Colors Input */}
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="Colors">
                      Colors<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="Colors"
                      id="Colors"
                      placeholder="Enter Colors"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Colors || ""}
                      isInvalid={touched.Colors && !!errors.Colors}
                    />
                    <ErrorMessage name="Colors" component="div" className="text-danger" />
                  </div>
                </Col>

                {/* Tags Input */}
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="Tags">
                      Tags<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="Tags"
                      id="Tags"
                      placeholder="Enter Tags"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Tags || ""}
                      isInvalid={touched.Tags && !!errors.Tags}
                    />
                    <ErrorMessage name="Tags" component="div" className="text-danger" />
                  </div>
                </Col>

                {/* Year Released Input */}
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="Year_released">
                      Year Released<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="Year_released"
                      id="Year_released"
                      placeholder="Enter Year Released"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Year_released || ""}
                      isInvalid={touched.Year_released && !!errors.Year_released}
                    />
                    <ErrorMessage name="Year_released" component="div" className="text-danger" />
                  </div>
                </Col>

                {/* Signature Input */}
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="Signature">
                      Signature<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="Signature"
                      id="Signature"
                      placeholder="Enter Signature"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Signature || ""}
                      isInvalid={touched.Signature && !!errors.Signature}
                    />
                    <ErrorMessage name="Signature" component="div" className="text-danger" />
                  </div>
                </Col>

                {/* Surface Input */}
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="surface">
                      Surface<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="surface"
                      id="surface"
                      placeholder="Enter Surface"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.surface || ""}
                      isInvalid={touched.surface && !!errors.surface}
                    />
                    <ErrorMessage name="surface" component="div" className="text-danger" />
                  </div>
                </Col>

                {/* Shipped As Input */}
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="shippedAs">
                      Shipped As<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="shippedAs"
                      id="shippedAs"
                      placeholder="Enter Shipped As"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.shippedAs || ""}
                      isInvalid={touched.shippedAs && !!errors.shippedAs}
                    />
                    <ErrorMessage name="shippedAs" component="div" className="text-danger" />
                  </div>
                </Col>

                {/* Timelines Input */}
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="Timelines">
                      Timelines<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="Timelines"
                      id="Timelines"
                      placeholder="Enter Timelines"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Timelines || ""}
                      isInvalid={touched.Timelines && !!errors.Timelines}
                    />
                    <ErrorMessage name="Timelines" component="div" className="text-danger" />
                  </div>
                </Col>


                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="Edition_Size">
                    Edition Size<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="Edition_Size"
                      id="Edition_Size"
                      placeholder="Enter Edition_Size"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Edition_Size || ""}
                      isInvalid={touched.Edition_Size && !!errors.Edition_Size}
                    />
                    <ErrorMessage name="Edition_Size" component="div" className="text-danger" />
                  </div>
                </Col>
              </Tab>

              {/* Details Tab */}
              <Tab eventKey="seo" title="SEO">
                {/* Details Field 1 */}
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="seo_title">
                    Seo Title<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="seo_title"
                      id="seo_title"
                      placeholder="Enter seo_title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.seo_title || ""}
                      isInvalid={touched.seo_title && !!errors.seo_title}
                    />
                    <ErrorMessage name="seo_title" component="div" className="text-danger" />
                  </div>
                </Col>

                {/* Details Field 2 */}
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="seo_keyword">
                    Seo Keyword<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="seo_keyword"
                      id="seo_keyword"
                      placeholder="Enter seo_keyword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.seo_keyword || ""}
                      isInvalid={touched.seo_keyword && !!errors.seo_keyword}
                    />
                    <ErrorMessage name="seo_keyword" component="div" className="text-danger" />
                  </div>
                </Col>




                
                <Col md={6}>
                  <div className="mb-3">
                    <BootstrapForm.Label htmlFor="seo_description">
                    Seo Description<sup>*</sup>
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      name="seo_description"
                      id="seo_description"
                      placeholder="Enter seo_description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.seo_description || ""}
                      isInvalid={touched.seo_description && !!errors.seo_description}
                    />
                    <ErrorMessage name="seo_description" component="div" className="text-danger" />
                  </div>
                </Col>


                <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Seo image</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
              </Tab>

     
              {/* Tab 1 */}
              <Tab eventKey="supporting Images" title="Supporting Images">
                {/* Tab 1 Field */}
               
                <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 1</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>


    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 2</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 3</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 4</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 5</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 6</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 7</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 8</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 9</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 10</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 11</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 12</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 13</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="file">Supporting Image 14</label>
        <input 
          type="file" 
          id="file" 
          onChange={handleFileChange} 
          className="form-control"
        />
      </div>
    </Col>
  
              </Tab>
            </Tabs>

            <div className="box-footer">
      <button type="submit" className="btn btn-success ladda-button" data-style="zoom-in">
        <span className="ladda-label">
          <i className="fa fa-save"></i> Save
        </span>
      </button>
      <a href="https://admin.artflute.com/admin/artwork" className="btn btn-default ladda-button" data-style="zoom-in">
        <span className="ladda-label">Cancel</span>
      </a>
    </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupForm;