import React, { useState } from 'react';
import { Card, Col, Modal, Button, Form as BootstrapForm } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { pendingArtworks } from 'Common/data/dashboard';
import { Formik, Form, Field } from 'formik';
interface Artwork {
    artistName: string;
    title: string;
    size: string;
    price: string;
    productImage: string;
    sl: string;
    action: string
}

const Status = ({ status }: any) => {
    switch (status) {
        case "In Stock":
            return (<span className="badge bg-success-subtle text-success"> {status}</span>)
        case "Low Stock":
            return (<span className="badge bg-warning-subtle text-warning"> {status}</span>)
        default:
            return (<span className="badge bg-danger-subtle text-danger"> {status}</span>)
    }
}

const StockReport = () => {
    const [show, setShow] = useState(false);
    const [selectedArtwork, setSelectedArtwork] = useState<Artwork | null>(null);


    const handleClose = () => setShow(false);
    const handleShow = (artwork: any) => {
        setSelectedArtwork(artwork);
        setShow(true);
    };

    const handleSubmit = (values: any) => {
        console.log('Form submitted with values:', values);
        handleClose();
    };

    return (
        <React.Fragment>
            <Col xxl={9}>
                <Card>
                    <Card.Header className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Artworks Approval</h4>
                        <div className="flex-shrink-0">
                            <Link to="/statistics" className="btn btn-soft-info btn-sm">
                                View all pending artworks<i className="ph-caret-double-right align-middle"></i>
                            </Link>
                        </div>
                    </Card.Header>

                    <Card.Body>
                        <div className="table-responsive table-card">
                            <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead className="text-muted table-light">
                                    <tr>
                                        <th scope="col">SL</th>
                                        <th scope="col">Artist Name</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Size</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(pendingArtworks || []).map((item, key) => (
                                        <tr style={{ cursor: 'pointer' }} key={key} onClick={() => handleShow(item)}>
                                            <td>
                                                <Link to="#" className="fw-medium link-primary">{item.sl}</Link>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-2">
                                                        <img src={item.productImage} alt="" className="avatar-xs rounded-circle" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <span className="text-reset">{item.artistName}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{item.title}</td>
                                            <td>
                                                <span className="text-secondary">{item.size}</span>
                                            </td>
                                            <td>
                                                <span className="text-secondary">{item.price}</span>
                                            </td>
                                            <td>
                                                Approve
                                                {/* {item.action} */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            {selectedArtwork && (
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Artworks Approval</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                artistName: selectedArtwork.artistName,
                                title: selectedArtwork.title,
                                approvalStatus: selectedArtwork.action,
                            }}
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleChange }) => (
                                <Form>
                                    <BootstrapForm.Group className="mb-3" controlId="artistName">
                                        <BootstrapForm.Label className='text-muted'>Artist Name : </BootstrapForm.Label>
                                        <BootstrapForm.Label className='ms-1'>{values.artistName} </BootstrapForm.Label>
                                    </BootstrapForm.Group>

                                    <BootstrapForm.Group className="mb-3" controlId="title">
                                        <BootstrapForm.Label className='text-muted'>Title : </BootstrapForm.Label>
                                        <BootstrapForm.Label className='ms-1'>{values.title} </BootstrapForm.Label>
                                    </BootstrapForm.Group>

                                    <BootstrapForm.Group>
                                        <BootstrapForm.Label>Approval Status</BootstrapForm.Label>
                                        <div>
                                            <BootstrapForm.Check
                                                inline
                                                label="Approve"
                                                name="approvalStatus"
                                                type="radio"
                                                id="approve"
                                                value="approve"
                                                checked={values.approvalStatus === 'approve'}
                                                onChange={handleChange}
                                            />
                                            <BootstrapForm.Check
                                                inline
                                                label="Disapprove"
                                                name="approvalStatus"
                                                type="radio"
                                                id="disapprove"
                                                value="disapprove"
                                                checked={values.approvalStatus === 'disapprove'}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </BootstrapForm.Group>

                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default StockReport;