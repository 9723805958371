import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nouislider from "nouislider-react";
import "../../../../node_modules/nouislider/distribute/nouislider.css";
import ProductSelectColor from './ProductSelectColor';
import ProductSize from './ProductSize';
import ProductDiscount from './ProductDiscount';
import ProductRating from './ProductRating';
import axios from 'axios';

const ProductCategoryFilter = () => {
    const [mincost, setMincost] = useState<number>(0);
    const [maxcost, setMaxcost] = useState<number>(2000);

    const [loader, setLoader] = useState<boolean>(false);
    const [categoryList, setCategoryList] = useState<any>([]);
    const [modalAddSeller, setModalAddSeller] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [dateFilter, setDateFilter] = useState('All');
    const [statusdate, setstatusdate] = useState<any>([]);;


    //Product Filter
    const onUpdate = (value: any) => {
        setMincost(value[0]);
        setMaxcost(value[1]);
    }
    useEffect(() => {
        onUpdate([0, 2000]);
    }, []);


    const fetchSellerList = async () => {
        setLoader(true);
        try {
            const tokenString = localStorage.getItem('authUser');
            let token = '';

            if (tokenString) {
                const tokenObject = JSON.parse(tokenString.replace('Bearer ', ''));
                token = tokenObject.token;
            }

            const response = await axios.get(
                `${process.env.REACT_APP_API_BASEURL}/auth/getall_category`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            setCategoryList(response.data);
        } catch (error) {
            console.error("Error fetching seller list:", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchSellerList();
    }, []);


    console.log(categoryList, "ddddddddddddddddddddddddddddddddd")
    return (
        <div>
           
            <ProductSize />
            <ProductRating />
        </div>
    )
}

export default ProductCategoryFilter