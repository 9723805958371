import React from 'react';
import { Image, ListGroup, Button, Container, Row, Col, Table, Tab, Tabs } from 'react-bootstrap';

const ArtworkDetail = () => {
  return (
    <Container className="page-content">
      <Row className="mb-3">
        <Col md={12}>
          <a href="https://admin.artflute.com/admin/artwork">
            <i className="fa fa-angle-double-left"></i> Back to all <span className="text-lowercase">artworks</span>
          </a>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Image
            src="https://s3.ap-southeast-1.amazonaws.com/art-flute-media/artworks/artwork-/thumbnail/compressed/10_06_24_06_36_04_IMG_20230526_183457_597.jpg"
            alt="Artwork"
            fluid
            rounded
          />
        </Col>
        <Col md={5}>
          <h3>Ryomen Sukuna</h3>
          <p>This is the best piece of artwork I have ever created..</p>
          <p>
            <strong>Category:</strong>
            <a href="">Drawing</a>
          </p>
          <p>
            <strong>Mediums:</strong>
            <a href="">Mixed Media</a>
          </p>
          <p>
            <strong>Size:</strong> 11.6 X 8.27
          </p>
          <p>
            <a className="badge bg-success" href="">Figurative</a>
            <a className="badge bg-success" href="">100_acid_free_reg_paper</a>
            <a className="badge bg-success" href="">Pink</a>
          </p>
          <p>
            <span>Original sale</span>
            <span><i className="fa fa-check-circle" style={{ color: 'green' }}></i></span>
            <span style={{ marginLeft: '5px' }}>Print Sale</span>
            <span><i className="fa fa-times-circle" style={{ color: 'red' }}></i></span>
            <span style={{ marginLeft: '5px' }}>Desktop Art sale</span>
            <span><i className="fa fa-times-circle" style={{ color: 'red' }}></i></span>
          </p>
          <form
            action="https://admin.artflute.com/admin/artwork/update/13943"
            method="POST"
            onSubmit={() => window.confirm('Do you really want to do this?')}
          >
            <Button href="https://admin.artflute.com/admin/artwork/13943/edit" variant="success">
              <i className="fa fa-pencil"></i> Edit this Artwork
            </Button>
            <input type="hidden" name="_token" value="f7JRF5Pw4SfN8WVAhWOOKRQOHBbnqh1baFV8NqBh" />
            <input name="status" type="hidden" value="0" />
            <Button type="submit" variant="primary">Disable this artwork</Button>
          </form>
          <form
            method="POST"
            action="/admin/artwork/delete/13943/force"
            onSubmit={() => window.confirm('Are you sure you want to delete this item?')}
          >
            <input type="hidden" name="_token" value="f7JRF5Pw4SfN8WVAhWOOKRQOHBbnqh1baFV8NqBh" />
            <Button type="submit" variant="danger">Delete this Artwork</Button>
          </form>
        </Col>
        <Col md={3}>
          <div className="box-body box-profile">
            <Image
              className="profile-user-img img-responsive img-circle"
              src="https://d3qxj82trr8cp9.cloudfront.net/profilepic/10_06_24_08_Ariba_9ae677f12a9b01fc63926a6287bcf83d.jpg"
              alt="User profile picture"
              roundedCircle
              fluid
            />
            <h3 className="profile-username text-center">Ariba Ayub</h3>
            <ListGroup className="list-group-unbordered">
              <ListGroup.Item className="text-center">
                <a href="mailto:aribaayub9120@gmail.com">
                  <i className="fa fa-envelope-o"></i> aribaayub9120@gmail.com
                </a>
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                <a href="https://admin.artflute.com/admin/artist/1932">
                  <i className="fa fa-eye"></i> View Profile
                </a>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Col>
        <Col md={6}>
          <p style={{ fontSize: '20px' }}>
            <span>Indian Price: <b><i className="fa fa-inr"></i> 300</b></span>
            <span style={{ marginLeft: '50px' }}>US Price: <b><i className="fa fa-usd"></i></b></span>
          </p>
        </Col>
      </Row>

      <Tabs defaultActiveKey="gallery" id="uncontrolled-tab-example">
        <Tab eventKey="gallery" title="Gallery">
          <Button
            variant="primary"
            id="addLowImage"
            data-toggle="modal"
            data-target="#addGalleryImage"
          >
            Add Artwork Image
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Image</th>
                <th>Dimensions</th>
                <th className="actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>22131</td>
                <td>
                  <img
                    src="https://s3.ap-southeast-1.amazonaws.com/art-flute-media/artworks/artwork-/thumbnail/compressed/10_06_24_06_36_04_IMG_20230526_183457_597.jpg"
                    alt="Artwork"
                    style={{ height: '240px', width: '340px', objectFit: 'cover' }}
                  />
                </td>
                <td>
                  <p><strong>Is high resolution?</strong> No</p>
                  <p><strong>Width: </strong></p>
                  <p><strong>Height: </strong></p>
                  <p><strong>Pixel: </strong></p>
                </td>
                <td className="item-actions">
                  <Button variant="link" href="https://admin.artflute.com/admin/artworkimage/22131/edit">Edit</Button>
                  <Button variant="link" href="https://admin.artflute.com/admin/artworkimage/22131" data-button-type="delete">Delete</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="highResolution" title="High Resolution">
          <Button
            variant="primary"
            id="addHighImage"
            data-toggle="modal"
            data-target="#addGalleryImage"
          >
            Add Artwork Image
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Image</th>
                <th>Dimensions</th>
                <th className="actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* Empty */}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="print" title="Print">
          <Button
            variant="primary"
            id="AddPrintAvailability"
            data-artwork="1"
            data-toggle="modal"
            data-target="#addPrint"
          >
            Add print availability
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Criteria</th>
                <th>Price - INR</th>
                <th>Price - USD</th>
                <th className="actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* Empty */}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="comments" title="Comments">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Comment/Review</th>
                <th>User</th>
                <th className="actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* Empty */}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="activity" title="Activity">
          <p>
            <a href="/admin/userCustomer/3027">Artflute</a> updated an artwork <a href="/admin/artwork/13943">Ryomen Sukuna</a> 2 months ago <a className="show-more" href="/admin/audit/feed/6a9c026d-27b6-4188-abbb-784dba6ae440">show more</a>
          </p>
          <p>
            <a href="/admin/userCustomer/8404">Ariba</a> created an artwork image of <a href="/admin/artwork/13943">Ryomen Sukuna</a> 2 months ago <a className="show-more" href="/admin/audit/feed/350b9946-3744-4671-9ba8-a341e5a68a25">show more</a>
          </p>
          <p>
            <a href="/admin/userCustomer/8404">Ariba</a> created an artwork <a href="/admin/artwork/13943">Ryomen Sukuna</a> 2 months ago <a className="show-more" href="/admin/audit/feed/a9825cf1-a0e9-408a-9bbe-73f87c6c432a">show more</a>
          </p>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ArtworkDetail;